<template>
  <div class="mbIndex">
    <span class="title">暂未提供开发选项，谢谢浏览！</span>
    <span class="sub">We have not provided a mobile terminal yet. Thank you for browsing!</span>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
.mbIndex {
  color: white;
  min-height: 100vh;
  background-image: linear-gradient(125deg, #2c3e50, #27ae60, #2980b9, #e74c3c, #8e44ad);
  background-size: 400%;
  animation: bgmove 20s infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 1.7rem;
    width: 80%;
  }

  .sub {
    font-size: 1.4rem;
    color: rgba(255, 255, 255, .8);
    width: 80%;
  }

}
@keyframes bgmove {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
