<template>
  <div class="login">
    <video class="animate__animated animate__fadeIn" id="login-video" src="../assets/video/back.mp4" autoplay loop muted width="100%" height="100%"></video>

    <!-- 登录框 -->
    <div class="animate__animated animate__flipInY login-box" v-loading="loading" ref="loginBox">
      <div class="welcome">
        <h2 class="title">Welcome to ZWJ Financial Statistics System</h2>
        <span class="sub">欢迎登录知味家财务统计系统</span>
      </div>

      <form class="login-form" action="/login" method="post">
        <div class="item">
          <i class="iconfont icon-user2"></i>
          <span class="line"></span>
          <input type="text" v-model="user.username">
        </div>
        
        <div class="item">
          <i class="iconfont icon-yypassword1"></i>
          <span class="line"></span>
          <input type="password" v-model="user.password" autocomplete="new-password">
        </div>

        <SlideVerify @receiveValue="getStatus" :key="reloadValue" v-if="showSlideVerify"></SlideVerify>

        <button class="submit" :class="{ hover: submitHovering }" type="submit" @mouseenter="submitHovering = true" @mouseleave="submitHovering = false" @click.prevent="doLogin">立即登录</button>
      </form>

      <div class="resetPwd" @click="resetPwd">忘记密码</div>
    </div>

    <!-- footer -->
    <div class="footer">
      <a class="link" href="https://beian.miit.gov.cn/" target="_blank">备案号：鄂ICP备2022003995号-1</a>
    </div>

    <!-- 忘记密码框 -->
    <div class="animate__animated animate__bounceInDown reset-wrapper" v-if="showReset" ref="resetBox">
      <div class="title-box">
        <div class="title">忘记密码</div>
        <div class="sub">通过用户名或设定的邮箱找回密码</div>
      </div>

      <div class="reset-form">
        <div class="group">
          <input type="text" v-model="user.username" placeholder="请输入用户名或邮箱">
        </div>

        <div class="group">
          <input type="text" v-model="reEmailCode" placeholder="请输入邮箱验证码">
          <span class="send-btn" v-if="!isSendResetCode" @click="sendResetCode">发送验证码</span>
          <span class="time" v-else>{{ time }}</span>
        </div>
      </div>

      <div class="reset-tip" v-if="showTip">{{ tipText }}</div>

      <div class="btns">
        <input class="prev" type="button" value="上一步" @click="gotoPrev(0)">
        <input class="next" type="button" value="下一步" @click="gotoNext">
      </div>
    </div>

    <!-- 重设密码框 -->
    <div class="animate__animated animate__bounceInDown reset-wrapper" v-loading="pwdLoading" v-if="showNewPwdBox" ref="newPwdBox">
      <div class="title-box">
        <div class="title">重设密码</div>
        <div class="sub">填写新密码并重新登录</div>
      </div>

      <div class="reset-form">
        <div class="group">
          <input type="password" v-model="newPwd" placeholder="请输入新密码" autocomplete="new-password">
        </div>

        <div class="group">
          <input type="password" v-model="reNewPwd" placeholder="请输入新密码" autocomplete="new-password">
        </div>
      </div>

      <div class="reset-tip" v-if="showNewPwdTip">{{ newPwdTipText }}</div>

      <div class="btns">
        <input class="prev" type="button" value="取消" @click="cancelReset">
        <input class="prev" type="button" value="上一步" @click="gotoPrev(1)">
        <input class="next" type="button" value="保存" @click="save">
      </div>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';
import md5 from 'js-md5';
import showOrHide from '../utils/showoOrHide';
import SlideVerify from '../components/SlideVerify.vue';

export default {
  data () {
    return {
      timer: null, // 定时器
      loading: false, // 登录 loading
      pwdLoading: false, // 修改密码成功 loading
      submitHovering: false,
      user: {
        username: '',
        password: '',
      },
      avatar: '',
      email: '',
      showReset: false,
      isSendResetCode: false,
      time: 60,
      reEmailCode: '', // 忘记密码邮箱验证码
      showTip: '', // 是否显示提示
      tipText: '', // 提示文字
      emailReg: /^\w{3,}(\.\w+)*@[a-z0-9]+(\.[a-z]{2,5}){1,2}$/,
      showNewPwdBox: false, // 是否显示重设密码框
      newPwd: '', // 新密码
      reNewPwd: '', // 新密码
      showNewPwdTip: false, // 是否显示新密码提示
      newPwdTipText: '', // 新密码提示文字
      // 当然确定要操作的用户名对象
      curUser: {
        id: 0,
        username: '',
      },
      isSameOldPwd: false, // 新密码与旧密码是否相同

      // 人机验证
      verifyNum: 0, // 验证次数
      showSlideVerify: false, // 是否显示人机验证
      slideValue: false, // 人机验证返回的通过后的结果
      status: false, // 向子组件发送的状态
      reloadValue: false, // 子组件重置标记
    }
  },

  methods: {
    // 初始化
    init () {
      clearTimeout(this.timer); // 清除定时器
      this.clearStatus(); // 清除相关状态
      this.setExpiration();
    },

    // 登录
    doLogin () {
      // 用户名不得为空
      if (!this.user.username) {
        this.$message({
          type: 'info',
          message: '请填写用户名！'
        })
        return;
      }
      
      // 密码不得为空
      if (!this.user.password) {
        this.$message({
          type: 'info',
          message: '请填写密码！'
        })
        return;
      }

      // 显示人机验证模块
      this.showSlideVerify = true;

      // 如果未进行人机验证
      if (!this.slideValue) {
        return;
      }
      // 如果已进行人机验证
      else {
        request.post('/login', { user: this.user, withPassword: true })
        .then(res => {
          if (res.data.code == 20000) {
            localStorage.setItem('token', res.data.token);
            this.$set(this.user, 'id', res.data.data.id);
            this.$set(this.user, 'avatar', res.data.data.userProfile[0].avatar);
            this.$set(this.user, 'email', res.data.data.userProfile[0].email);

            // 去除密码项
            let _user = {};
            Object.assign(_user, this.user);
            delete _user?.password; // 不显示密码项
            localStorage.setItem('user', JSON.stringify(_user));
            this.loading = true;

            // 改变全局 user 的值以更新全部头像
            this.$store.state.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';

            this.$message({
              type: 'success',
              message: '登录成功！',
              duration: 1000,
              onClose: () => {
                this.loading = false;
                this.$router.push('/');
              }
            })
          }
          else {
            // 重置人机验证 - 重新加载子组件并将其置为初始未通过人机验证状态
            this.reloadValue = !this.reloadValue; // 重新加载
            this.slideValue = false; // 人机验证初始状态

            this.$message({
              type: 'error',
              message: '用户名或密码错误！'
            })
          }
        })
      }

    },

    // 从子组件获取返回的通过结果
    getStatus (val) {
      this.slideValue = val;
    },

    // 忘记密码
    resetPwd () {
      showOrHide(this.$refs.loginBox, 'animate__flipInY', 'animate__bounceOutDown');
      
      this.timer = setTimeout(() => {
        this.showReset = true; // 显示忘记密码框
      }, 300)
    },

    // 发送邮箱验证码
    sendResetCode () {
      this.tipText = ''; // 清除提示内容

      // 检验当前输入的是否邮箱
      if (!this.user.username) {
        this.showTip = true;
        this.tipText = '请填写用户名或邮箱！';
        return;
      }
      
      // 如果是邮箱
      if (this.emailReg.test(this.user.username)) {
        // 验证当前输入的邮箱是否存在
        request.get('/userprofile', {
          params: {
            email: this.user.username
          }
        })
        .then (res => {
          if (res.data.message) {
            let data = res.data.data;

            // 如果填写的邮箱存在
            // 发送验证码
            request.post('/email', {
              username: this.user.username,
              email: this.user.username
            })
            .then (res => {
              if (res.data.message) {
                // 清除提示
                this.showTip = false;
                this.tipText = '';

                let resetCode = res.data.code; // 从后台获取验证码
                // 设置过期时间为 10 分钟
                let expirationTime = new Date().getTime() + 10 * 60 * 1000;
                sessionStorage.setItem('expirationTime', expirationTime);
                sessionStorage.setItem('resetCode', resetCode);
                
                // 显示倒计时秒数
                this.isSendResetCode = true;
                // 倒计时
                this.timer = setInterval(() => {
                  this.time--;
                  if (this.time == 0) {
                    clearInterval(this.timer);
                    this.isSendResetCode = false;
                  }
                }, 1000);
              }
              else {
                this.showTip = true;
                this.tipText = '发送失败，请联系管理员！';
              }
            })
          }
          else {
            this.showTip = true;
            this.tipText = '您填写的邮箱不存在！';
          }
        })
      }
      // 如果不是邮箱,则要通过该用户名找到对应绑定的邮箱,并发送验证码,如果没绑定邮箱,则提示失败
      else {
        request.get('/user', {
          params: {
            username: this.user.username
          }
        })
        .then (res => {
          if (res.data.message) {
            let data = res.data.data.userProfile[0];
            
            this.showTip = true;
            
            // 如果邮箱不存在
            if (!data.email) {
              this.tipText = '您填写的邮箱格式不合法，无法发送验证码！';
            }
            // 如果存在,则发送验证码
            else {
              this.tipText = `您的邮箱是：${ data.email }，请填写收到的验证码`;

              // 发送验证码
              request.post('/email', {
                username: this.user.username,
                email: data.email
              })
              .then (res => {
                if (res.data.message) {
                  let resetCode = res.data.code; // 从后台获取验证码
                  // 设置过期时间为 10 分钟
                  let expirationTime = new Date().getTime() + 10 * 60 * 1000;
                  sessionStorage.setItem('expirationTime', expirationTime);
                  sessionStorage.setItem('resetCode', resetCode);
                  
                  // 显示倒计时秒数
                  this.isSendResetCode = true;
                  // 倒计时
                  this.timer = setInterval(() => {
                    this.time--;
                    if (this.time == 0) {
                      clearInterval(this.timer);
                      this.isSendResetCode = false;
                    }
                  }, 1000);
                }
                else {
                  this.showTip = true;
                  this.tipText = '发送失败，请联系管理员！';
                }
              })
            }
          }
          else {
            this.showTip = true;
            this.tipText = '您输入的用户名不存在！';
          }
        })
      }
    },

    // 清除验证码
    clearStatus () {
      clearInterval(this.timer);
      this.showTip = false;
      this.tipText = '';
      this.time = 60;
      this.isSendResetCode = false;
      sessionStorage.removeItem('expirationTime');
      sessionStorage.removeItem('resetCode');
    },

    // 验证码自动过期
    setExpiration () {
      if (new Date().getTime() > sessionStorage.getItem('expirationTime')) {
        sessionStorage.removeItem('expirationTime');
        sessionStorage.removeItem('resetCode');
      }
    },

    // 上一步
    gotoPrev (index) {
      // 清除相关状态
      this.clearStatus();

      // 从忘记密码框到登录框
      if (index == 0) {
        // 隐藏忘记密码框
        showOrHide(this.$refs.resetBox, 'animate__bounceInDown', 'animate__bounceOutDown');
        
        // 显示登录框
        this.timer = setTimeout(() => {
          showOrHide(this.$refs.loginBox, 'animate__bounceOutDown', 'animate__bounceInDown');
  
          this.timer = setTimeout(() => {
            this.showReset = false; // 隐藏忘记密码框
          }, 300);
        }, 300)
      }

      // 从重设密码框到忘记密码框
      if (index == 1) {
        // 隐藏重设密码框
        showOrHide(this.$refs.newPwdBox, 'animate__bounceInDown', 'animate__bounceOutDown');
        
        // 显示忘记密码框
        this.timer = setTimeout(() => {
          showOrHide(this.$refs.resetBox, 'animate__bounceOutDown', 'animate__bounceInDown');
  
          this.timer = setTimeout(() => {
            this.showNewPwdBox = false; // 隐藏重设密码框
          }, 300);
        }, 300)
      }
    },

    // 下一步
    gotoNext () {
      // 邮箱或用户名不得为空
      if (!this.user.username) {
        this.showTip = true;
        this.tipText = '邮箱或用户名不得为空！';
        return;
      }

      // 验证码不得为空
      if (!this.reEmailCode) {
        this.showTip = true;
        this.tipText = '验证码不得为空！';
        return;
      }

      // 如果输入的是邮箱,先检验该邮箱是否存在
      if (this.emailReg.test(this.user.username)) {
        // 验证当前输入的邮箱是否存在
        request.get('/userprofile', {
          params: {
            email: this.user.username
          }
        })
        .then (res => {
          if (res.data.message) {
            let data = res.data.data;

            // 如果填写的邮箱存在
            // 检测验证码
            if (sessionStorage.getItem('resetCode')) {
              // 验证码过期
              if (new Date().getTime() > sessionStorage.getItem('expirationTime')) {
                sessionStorage.removeItem('expirationTime');
                sessionStorage.removeItem('resetCode');
  
                this.showTip = true;
                this.tipText = '验证码已过期，请重新获取！';
                return;
              }
  
              // 验证码错误
              if (this.reEmailCode != sessionStorage.getItem('resetCode')) {
                this.showTip = true;
                this.tipText = '邮箱验证码错误！';
                return;
              }
            }
            else {
              this.showTip = true;
              this.tipText = '验证码已过期，请重新获取！';
              return;
            }

            // 验证码正确
            if (this.reEmailCode == sessionStorage.getItem('resetCode')) {
              this.$set(this.curUser, 'id', data.user_id);
              this.$set(this.curUser, 'username', data.userBase.username);

              // 清除相关状态
              this.clearStatus();
              // 验证码失效
              sessionStorage.removeItem('expirationTime');
              sessionStorage.removeItem('resetCode');

              // 隐藏忘记密码框
              showOrHide(this.$refs.resetBox, 'animate__bounceInDown', 'animate__bounceOutDown');
              
              // 显示填写新密码框
              this.timer = setTimeout(() => {
                this.showNewPwdBox = true; // 显示重设密码框
              }, 300)
            }
          }
          else {
            this.showTip = true;
            this.tipText = '您填写的邮箱不存在！';
          }
        })
      }
      // 如果输入的是用户名,先检验该用户名是否存在
      else {
        request.get('/user', {
          params: {
            username: this.user.username
          }
        })
        .then (res => {
          if (res.data.message) {
            let data = res.data.data;

            // 验证码过期
            if (!sessionStorage.getItem('resetCode')) {
              this.showTip = true;
              this.tipText = '验证码已过期，请重新获取！';
              return;
            }

            // 填写的验证码是否正确
            if (sessionStorage.getItem('resetCode') && this.reEmailCode == sessionStorage.getItem('resetCode')) {
              this.$set(this.curUser, 'id', data.id);
              this.$set(this.curUser, 'username', data.username);

              // 清除相关状态
              this.clearStatus();

              // 检测当前用户名是否绑定了邮箱
              if (data.userProfile[0].email) {
                // 隐藏忘记密码框
                showOrHide(this.$refs.resetBox, 'animate__bounceInDown', 'animate__bounceOutDown');

                this.timer = setTimeout(() => {
                  // 显示新密码框
                  this.showNewPwdBox = true;
                }, 300);
              }
              // 如果没有绑定
              else {
                this.showTip = true;
                this.tipText = '当前用户没有绑定邮箱，请联系管理员！';
              }
            }
            else {
              this.showTip = true;
              this.tipText = '验证码错误！';
            }
          }
          else {
            this.showTip = true;
            this.tipText = '用户名检测失败，请联系管理员！';
          }
        })
      }
    },

    // 保存新密码
    save () {
      // 密码不得为空
      if (!this.newPwd) {
        this.showNewPwdTip = true;
        this.newPwdTipText = '请填写新密码！';
        return;
      }

      // 密码必须是数字字母下划线中的至少 2 种组成,数字不能作为开头,且是 6~16 位
      let pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[A-Za-z_][A-Za-z_0-9]{5,16}$/g;
      if (!pwdReg.test(this.newPwd.trim()) && !pwdReg.test(this.reNewPwd.trim())) {
        this.showNewPwdTip = true;
        this.newPwdTipText = '密码必须是数字、字母、下划线中的至少 2 种组成，数字不能作为开头，且是 6~16 位';
        return;
      }

      // 两次密码须一致
      if (this.newPwd != this.reNewPwd) {
        this.showNewPwdTip = true;
        this.newPwdTipText = '再次输入的密码不一致！';
        return;
      }

      // 不得与旧密码相同
      request.get(`/user`, {
        params: {
          id: this.curUser.id,
          withPassword: true
        }
      })
      .then (res => {
        if (res.data.message) {
          let data = res.data.data;
          
          if (data) {
            data.password = data.password.slice(10);
          
            if (data.password == md5(this.newPwd) || data.password == md5(this.reNewPwd)) {
              this.showNewPwdTip = true;
              this.newPwdTipText = '新密码不能与旧密码相同！';
            }
            else {
              this.pwdLoading = true;

              // 保存新密码
              request.put(`/user/${ this.curUser.id }`, {
                password: this.newPwd
              })
              .then (res => {
                // 清空提示
                this.showNewPwdTip = false;
                this.newPwdTipText = '';
          
                this.$message({
                  type: 'success',
                  message: '修改成功！',
                  onClose: () => {
                    this.pwdLoading = false;
                    location.reload();
                  }
                })
              })
            }
          }
        }
      })
    },

    // 取消重设密码 - 直接刷新
    cancelReset () {
      location.reload();
    },
  },

  mounted () {
    this.init();
  },

  components: {
    'SlideVerify': SlideVerify,
  }
}
</script>

<style lang="scss" scoped>
input::placeholder {
  color: rgba(255, 255, 255, .5);
}

input {
  border: 1px solid transparent;
  outline: medium;
  background-color: transparent;
}

#login-video {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  object-fit: cover;
}

.login-box {
  background: rgba(16, 46, 97, .9);
  width: 32rem;
  padding: 4rem 0;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  margin-top: -20rem;
  margin-left: -15rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .welcome {
    max-width: 26rem;

    .title {
      font-size: 2rem;
      margin-bottom: .5rem;
    }

    .sub {
      font-size: 1.3rem;
      color: rgba(255, 255, 255, .5);
    }
  }

  .login-form {
    margin: 3rem 0 0;
    max-width: 26rem;
    width: 26rem;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      padding: 0 2rem .5rem;
      margin-bottom: 2rem;
      color: rgba(255, 255, 255, .5);

      .line {
        margin: 0 1rem;

        &:after {
          content: '';
          width: 1px;
          height: 1.4rem;
          background: rgba(255, 255, 255, .2);
          display: block;
          transform: scaleX(.5);
        }
      }

      input {
        background: none;
        border: none;
        color: white;
      }
    }

    .submit {
      flex: 1;
      border: none;
      background-color: rgba(35, 93, 183, 1);
      clear: both;
      padding: 1rem 0;
      color: white;
      cursor: pointer;
      transition: .3s ease;

      &.hover {
        background-color: rgba(24, 70, 142, 1);
        transition: .3s ease;
      }
    }
  }

  .resetPwd {
    padding: 1rem 0 0 0;
    margin: 0.5rem 3rem 0 auto;
    cursor: pointer;
  }
}

.reset-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;
  width: 32rem;
  padding: 4rem 0;
  margin-left: -15rem;
  margin-top: -20rem;
  background: rgba(16, 46, 97, 0.9);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title-box {
    width: 26rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .title {
      font-size: 1.7rem;
      margin-bottom: 0.5rem;
    }

    .sub {
      color: rgba(255, 255, 255, .5);
      font-size: 1.3rem;
    }
  }

  .reset-form {
    width: 26rem;
  
    .group {
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 1rem 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      input {
        flex: 1;
        padding: 1rem;
        padding-left: 0;
        color: white;
      }
    }
  }

  .send-btn {
    cursor: pointer;
  }

  .reset-tip {
    margin-top: 1rem;
    width: 26rem;
    font-size: 1.3rem;
    color: chartreuse;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 26rem;
    margin-top: 2rem;

    input {
      padding: 1rem 2rem;
      color: white;
      background-color: rgb(35, 93, 183);
      cursor: pointer;

      &.prev {
        background-color: rgb(35, 93, 183);
      }

      &.next {
        background-color: #22C55E;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 6rem;
  background: rgba(16, 46, 97, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .link {
    color: white;
    text-decoration: none;
    font-weight: normal;
  }
}
</style>
