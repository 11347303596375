import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '../views/Login'

import layout from '../views/Layout'
import home from '../views/Home'
import goodslist from '../views/Goodslist'
import weiyucanying from '../views/Weiyucanying'
import shiweinu from '../views/Shiweinu'
import meicai from '../views/Meicai'
import meituan from '../views/Meituan'
import taobao from '../views/Taobao'
import weixin from '../views/Weixin'
import qita from '../views/Qita'
import user from '../views/User'
import recycle from '../views/Recycle'
import slideverify from '../components/SlideVerify'
import notfound from '../views/NotFound'
import MbIndex from '../views/MbIndex'
import IEDetail from '../views/IEDetail'

Vue.use(VueRouter)

const routes = [
  // 移动端路由
  {
    path: '/mb_index',
    component: MbIndex
  },

  // pc端路由
  {
    path: '/login',
    component: login
  },
  {
    path: '/',
    component: layout,
    children: [
      {
        path: '/',
        component: home,
      },
      {
        path: '/goodslist',
        component: goodslist
      },
      {
        path: '/weiyucanying',
        component: weiyucanying
      },
      {
        path: '/shiweinu',
        component: shiweinu
      },
      {
        path: '/meicai',
        component: meicai
      },
      {
        path: '/meituan',
        component: meituan
      },
      {
        path: '/taobao',
        component: taobao
      },
      {
        path: '/weixin',
        component: weixin
      },
      {
        path: '/qita',
        component: qita
      },
      {
        path: '/user',
        component: user
      },
      {
        path: '/recycle',
        component: recycle
      },
      {
        path: '/slideverify',
        component: slideverify
      },
      {
        path: 'iedetail',
        component: IEDetail
      }
    ]
  },
  /* 当进入 layout 和 home 时直接跳转到 dashboard 默认首页 */
  {
    path: '/layout',
    redirect: '/'
  },
  {
    path: '/home',
    redirect: '/'
  },
  // 自定义404页面
  {
    path: '*',
    component: notfound
  },
  
  // {
    // path: '/about',
    // name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
// 未登录则跳转到登录页
router.beforeEach((to, from, next) => {
  /**
   * （1）全局前置导航配置: 页面跳转时定位到顶部
   */
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;

  if (to.path == '/login') {
    if (localStorage.getItem('token')) {
      next('/');
    }
    else {
      next();
    }
  }
  else {
    if (localStorage.getItem('token')) {
      next();
    }
    else {
      if (to.path == '/mb_index') {
        next();
      } else {
        next('/login');
      }
    }
  }
})

// （2）全局后置导航配置: 页面跳转时定位到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

export default router;
