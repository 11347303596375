/*
	通过 mutation 间接更新 state 的多个方法的对象
*/
/* import {
} from './mutation-types'; */

// import request from '../utils/request';

export default {
}
