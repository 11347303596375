<template>
  <div class="ie-detail">
    <div class="backdrop transparent" @click="removeAllBackdrop" v-if="showBackdrop"></div>

    <!-- 新增/修改 -->
    <div class="animate__animated animate__bounceInDown faster addedit-box" v-if="showAddEditBox" ref="addEditBox">
      <div class="item">
        <el-date-picker
          v-model="yearMonth"
          type="month"
          placeholder="选择年月">
        </el-date-picker>
      </div>

      <div class="item">
        <input class="no-spin" type="number" placeholder="本月美团收入" v-model="mtincome">
      </div>
      
      <div class="item">
        <input class="no-spin" type="number" placeholder="本月饿了么收入" v-model="eleincome">
      </div>

      <div class="btns">
        <input class="btn-item cancel" type="button" value="取消" @click="cancelAddEdit">
        <input class="btn-item confirm" type="button" value="确定" @click="saveAddEdit">
      </div>
    </div>

    <div class="header">
      <div class="title">income and expenditure detail</div>
      
      <div class="opers">
        <div class="select-year">
          <el-date-picker
            v-model="curYear"
            type="year"
            placeholder="选择年"
            @change="selectYear">
          </el-date-picker>
        </div>

        <div class="add" @click="doAddItem"><i class="iconfont icon-add-select"></i> add income</div>
      </div>
    </div>

    <!-- 图表 -->
    <div class="chart-box" id="chart-box"></div>

    <!-- 列表 -->
    <div class="detail-list">
      <div class="title">Manage IEList</div>

      <div class="list-head">
        <span class="head-item col-8">date</span>
        <span class="head-item">mtincome</span>
        <span class="head-item">eleincome</span>
        <span class="head-item">expenditure</span>
        <span class="head-item col-8">total</span>
        <span class="head-item col-8">contrast</span>
        <span class="head-item col-8">percent</span>
        <span class="head-item">Edit</span>
      </div>
      
      <div class="list-body" v-for="(item, index) in ielist" :key="index">
        <span class="body-item col-8">{{ item.date }}</span>
        <span class="body-item">{{ item.mtincome | twoDecimal }}</span>
        <span class="body-item">{{ item.eleincome | twoDecimal }}</span>
        <span class="body-item">{{ item.expenditure | twoDecimal }}</span>
        <span class="body-item col-8">{{ item.sum | twoDecimal }}</span>
        
        <span class="body-item col-8 center">
          <i class="iconfont icon-up tag" v-if="item.sum - item.prev > 0"></i>
          <i v-else-if="item.prev == 0">-</i>
          <i class="iconfont icon-up down tag" v-else></i>
        </span>
        
        <span class="body-item col-8">
          <span v-if="Math.abs(item.prev) > 0">{{ item.sum && item.prev && Math.abs((item.sum - item.prev) / item.prev * 100).toFixed(2) + '%' }}</span>
          <span v-else>-</span>
        </span>

        <span class="body-item">
          <i class="iconfont icon-i-more i-more" @click="doShowEditBox(index)"></i>
          
          <div class="edit-btns" v-if="editBoxIndex == index">
            <span class="btns btn-edit" @click="editCurIEItem(item.id, item)"><i class="iconfont icon-Edit"></i> edit</span>
            <span class="btns btn-del" @click="deleteCurIEItem(item.id)"><i class="iconfont icon-delete_light1"></i> delete</span>
          </div>
        </span>
      </div>

      <div class="empty" v-if="empty">暂无数据...</div>
      
      <div class="list-foot">
        <span class="foot-item">年总收入: <span class="num">{{ yearIncome | twoDecimal }}</span></span>
        <span class="foot-item">月均收入: <span class="num">{{ (yearIncome / ielist?.length) | twoDecimal }}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';
import Backdrop from '../components/Backdrop.vue';

export default {
  data () {
    return {
      timer: null,
      showBackdrop: false, // 显示背景遮罩
      isAbandon: 0, // 防止双击
      empty: false, // 暂无数据
      curYear: new Date().getFullYear() + '', // 注意,类型是 string,以避免可能出现的错误
      ielist: [], // 年收支列表
      showAddEditBox: false, // 显示新增 box
      yearMonth: '', // 新增条目的年月
      mtincome: '', // 本月美团收入
      eleincome: '', // 本月饿了么收入
      expenditure: '', // 本月总支出
      yearIncome: 0, // 当前年总收入
      editBoxIndex: -1, // 显示当前编辑 box,-1代表全部不显示
      curId: 0, // 当前编辑条目的 id,默认为 0,表示不是编辑状态
    }
  },

  methods: {    
    // 获取一年的账单
    getYearBills (year=this.curYear) {
      return new Promise(resolve => {
        request.get(`/bill`, { params: { year } })
        .then (res => {
          if (res.data.message) {
            if (res.data?.data?.rows?.length > 0) {
              let data = res.data.data.rows;
              resolve(data);
            }
            else {
              resolve([]);
            }
          }
        })
      })
    },

    // 获取收支列表函数
    getChartList (params = { year: this.curYear }) {
      return new Promise(async (resolve, reject) => {
        // 根据年份 year 设置当前年份 1 月和 12 月的时间。
        // 注意,1 年的起始时间需要从去年的最后一天算到今年的最后一天
        let fromTime = params?.year -1 + '-12-31';
        let toTime = params?.year + '-12-31';
        let _params = { fromTime, toTime };

        request.get(`/ielist`, { params: _params })
        .then (async res => {
          if (res.data.message) {
            if (res.data?.data?.length > 0) {
              let data = res.data.data;
              let chartData = []; // 图表数据
              
              // 存在数据
              // 先需要清空年收入,以免叠加
              this.ielist = [];
              this.yearIncome = 0;

              // 获取当前年的所有账单
              let yearData = await this.getYearBills(params?.year);

              // 如果本年数据为空,则直接归零
              if (yearData?.length == 0) {
                this.ielist = [];
                this.yearIncome = 0;
                resolve({ data: [], chartData: [] });
                
                return;
              }

              let sumArr = [];
              // 必须是 i <= data.length,否则添加的月份数据与有支出但未添加的月份数据对应不上
              for (let i = 1; i <= data.length; i++) {
                let ym = `${ params?.year }-${ i >= 10 ? i : '0' + i }`;
                let ymBills = yearData?.length > 0 && yearData.filter(i => i.date.includes(ym));
                let sum = 0;
                ymBills.map(j => sum += j.amount);
                sumArr.push(sum);
              }

              // 获取第一个到最后一个大于 0 的月份数字,再倒序赋值
              let lastIndex = sumArr.findLastIndex(n => n > 0);
              if (lastIndex !== -1) {
                // 支出需要倒序才可以与页面的倒序一致
                sumArr = sumArr.slice(0, lastIndex + 1).reverse();
              }

              // 后端返回的列表设置为日期倒序
              data.forEach((v, i) => {
                v.expenditure = sumArr[i];
                
                // 添加月合计金额
                v.sum = v.mtincome + v.eleincome - v.expenditure;
                
                // 从1~12月排序
                chartData.push(Math.round(v.sum * 100) / 100);
                
                // 计算年收入
                this.yearIncome = this.yearIncome + v.sum;
              })

              // 年总收入取小数点后 2 位
              // this.yearIncome = this.yearIncome;

              // 倒序,chartData 需要矫正
              chartData = chartData.reverse();

              // 计算上个月的收入
              let len = data.length;
              data.forEach((v, i) => {
                v.prev = data[i+1] ? [data[i+1]] : 0;
                if (v.prev) {
                  v.prev = v.prev[0]?.mtincome + v.prev[0]?.eleincome - v.prev[0]?.expenditure || 0
                }
              })

              this.ielist = data;

              resolve({ data, chartData });
            }
            // 不存在数据
            else {
              this.ielist = [];
              this.yearIncome = 0;
              resolve({ data: [], chartData: [] });
            }
          }
        })
      })
    },

    // 配置图表
    async setChart (year = this.curYear) {
      let ieChart = this.$echarts.init(document.getElementById('chart-box'));

      // 获取收支数据
      let data = await this.getChartList({ year });
      this.empty = this.ielist.length == 0 ? true : false;

      let option = {
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data.chartData || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 默认为 0
            type: 'line',
            smooth: true
          }
        ],
        tooltip: {
          axisPointer: {
            // 坐标轴指示器配置项。
            type: 'cross', // 'line' 直线指示器  'shadow' 阴影指示器  'none' 无指示器  'cross' 十字准星指示器。
            axis: 'auto', // 指示器的坐标轴。
            snap: true // 坐标轴指示器是否自动吸附到点上
          },
          showContent: true
        }
      }

      option && ieChart.setOption(option);
    },

    // 选择年
    async selectYear (value) {
      if (!value) {
        this.curYear = new Date().getFullYear() + '';
        this.setChart();
        return;
      }

      // 标准化时间格式为 yyyy
      let year = new Date(value).getFullYear();
      if (!!year) this.curYear = year + ''; // 当前年份必须随时调整,且类型为 string

      // 获取当前年的数据
      let res = await this.getChartList({ year });

      if (res.data?.length > 0) {
        this.empty = false; // 隐藏无数据标记
        this.setChart(); // 获取数据并赋值
      }
      else {
        this._message('info', '没有当前年份的数据！');
        this.setChart(this.curYear);
        this.empty = true;
      }
    },
    
    // 显示新增条目 box
    doAddItem () {
      this.showBackdrop = true;   // 背景遮罩
      this.showAddEditBox = true; // 新增 box
      this.editBoxIndex = -1;     // 表示当前是新增

      // 清除赋值
      this.yearMonth = '';
      this.mtincome = '';
      this.eleincome = '';
    },
    
    // 取消新增条目
    cancelAddEdit () {
      this.yearMonth = null;
      this.mtincome = null;
      this.eleincome = null;

      if (this.$refs.addEditBox) {
        this.$refs.addEditBox.classList.remove('animate__bounceInDown');
        this.$refs.addEditBox.classList.add('animate__zoomOutDown');
      }

      this.timer = setTimeout(() => {
        this.showAddEditBox = false;
        this.showBackdrop = false;
      }, 300)
    },

    // 保存新增/编辑条目
    saveAddEdit () {
      // 验证
      if (!this.yearMonth || !this.mtincome || !this.eleincome) {
        this._message('info', '请完整填写以上内容！');
        return;
      }

      // 防止双击
      if (this.isAbandon > 0) {
        return;
      }
      this.isAbandon++;
 
      let params = {
        date: this._getDatetime(this.yearMonth).substring(0, 7),
        mtincome: this.mtincome,
        eleincome: this.eleincome,
      }

      // 如果 id 存在,表明当前处于编辑状态
      if (this.curId > 0) {
        request.put(`/ielist/${ this.curId }`, params)
        .then (async res => {
          if (res.data.message) {
            this._message('success', '修改成功！');
            this.cancelAddEdit();
            this.setChart();
          }
        })
      }
      // 向数据库新增
      else {
        request.post(`/ielist`, params)
        .then (async res => {
          if (res.data.message) {
            this.isAbandon--; // 恢复防止双击
  
            this._message('success', '新增成功！');
            this.cancelAddEdit();
            this.setChart();
          }
        })
        .catch(() => {
          this._message('error', '新增失败！');
        })
      }
    },

    // 显示编辑功能
    doShowEditBox (index) {
      this.showBackdrop = true;
      this.editBoxIndex = index;
    },

    // 移除编辑 box
    removeAllBackdrop () {
      // 移除背景遮罩
      if (this.editBoxIndex > -1) {
        this.showBackdrop = false;
      }

      // 移除编辑 box
      this.editBoxIndex = -1;
    },

    // 编辑当前条目
    editCurIEItem (id, item) {
      this.editBoxIndex = -1; // 隐藏当前编辑 box
      this.showAddEditBox = true; // 显示编辑 box,已带背景
      this.curId = id; // 表示当前处于编辑条目状态,非新增 box

      // 给编辑 box 项赋值
      this.yearMonth = item.date;
      this.mtincome = item.mtincome;
      this.eleincome = item.eleincome;
    },

    // 删除当前条目
    deleteCurIEItem (id) {
      this.$confirm('删除后不可恢复，确定继续？', '删除', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then (() => {
        request.delete(`/ielist/${ id }`)
        .then (res => {
          if (res.data.message) {
            this._message('success', '删除成功！');
            this.setChart();
          }
        })
      })
      // 取消
      .catch(() => {
        this.editBoxIndex = -1;
        this.showBackdrop = false;
      })
    },
    
    // 获取日期时间格式
    _getDatetime (time) {
      let curdate = new Date(time);
      let y = curdate.getFullYear();
      let m = curdate.getMonth() + 1;
      let d = curdate.getDate();
      let hh = curdate.getHours();
      let mm = curdate.getMinutes();
      let ss = curdate.getSeconds();

      m = m >= 10 ? m : '0' + m;
      d = d >= 10 ? d : '0' + d;
      hh = hh >= 10 ? hh : '0' + hh;
      mm = mm >= 10 ? mm : '0' + mm;
      ss = ss >= 10 ? ss : '0' + ss;

      let res = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss;
      return res;
    },

    // 自定义提示
    _message (type, message, onClose=()=>{}, duration=3000) {
      this.$message({
        type,
        message,
        duration,
        onClose: onClose && onClose()
      })
    },

    // 初始化
    async init () {
      this.setChart();
    }
  },

  mounted () {
    this.init();
  },

  components: {
    'backdrop': Backdrop
  },

  watch: {
    curYear (newval) {
      if (!newval) {
        this.curYear = new Date().getFullYear() + '';
      }
    }
  },

  filters: {
    twoDecimal (value) {
      if (!value) return '-';
      // return value.toFixed(2);
      return Math.round(value * 100) / 100;
    }
  }

}
</script>

<style lang="scss" scoped>
.ie-detail {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .title {
      font-size: 2.4rem;
  
      &:before {
        content: '';
        width: .2rem;
        height: 100%;
        background: red;
      }
    }

    .opers {
      display: flex;

      .select-year {
        width: 20rem;
        float: left;
        margin-right: 1rem;

        .el-date-editor.el-input {
          width: inherit;
        }
      }
      
      .add {
        padding: .5rem 1rem;
        border-radius: .8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F7FAFC;
        border: 1px solid #E2E8F0;
        color: #22c55e;
        cursor: pointer;
        transition: .3s ease;
  
        &:hover {
          transition: .3s ease;
          background: #eee8e8;
        }
      }
    }
  }
 
  .addedit-box {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 32;
    margin-left: -20rem;
    margin-top: -20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40rem;
    height: fit-content;
    padding: 2rem;
    background: white;
    border-radius: .8rem;
    filter: drop-shadow(rgba(0,0,0,.08) 1.2rem 1.2rem 4rem);

    .item {
      width: 35rem;
      margin: auto;
      margin-bottom: 1.5rem;

      &.last-child {
        margin-bottom: 0;
      }

      .el-date-editor.el-input {
        width: 100%;
      }

      input {
        background-color: #f0fafa;
        padding: 1.5rem 0rem 1.5rem 1rem;
        border-radius: .8rem;
        text-indent: 1rem;
        border: .1rem solid transparent;
        width: calc(100% - 1rem);
      }
    }

    .btns {
      display: flex;
      justify-content: center;

      .btn-item {
        margin-right: 2rem;
        padding: 1.45rem 0rem;
        border-radius: .8rem;
        cursor: pointer;
        width: 16.5rem;

        &.cancel {
          background-color: #d9fbe6;
          color: #22c55e;
          transition: .3s ease;

          &:hover {
            background-color: #d2ebdc;
            transition: .3s ease;
          }
        }

        &.confirm {
          background-color: #22c55e;
          color: white;
          transition: .3s ease;

          &:hover {
            background-color: #16a34a;
            transition: .3s ease;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  
  .chart-box {
    width: 100%;
    min-height: 60vh;
    background: #f9f5f5;
    border: 1px solid #e6f1f1;
    border-radius: .8rem;
  }

  .detail-list {
    background: white;
    margin-top: 2rem;
    border-radius: .8rem;
    border: 1px solid #f6f1f1;

    .title {
      border-bottom: 1px solid #E2E5E8;
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
    }

    .list-head {
      padding: 1.5rem 2rem;
      display: flex;
      justify-content: space-between;

      .head-item {
        width: 12.5%;
        display: flex;
        font-size: 1.7rem;

        &.center {
          justify-content: center;
        }

        @for $i from 1 through 20 {
          &.col-#{$i} { width: 1rem * $i };
        }
      }
    }
    
    .list-body {
      padding: 1.5rem 2rem;
      display: flex;
      justify-content: space-between;

      &:nth-child(odd) {
        background: #f4f5f6;
        border: 1px solid rgba(226, 229, 232, .1);
        border-left: transparent;
        border-right: transparent;
      }

      .body-item {
        width: 12.5%;
        display: flex;
        align-items: center;
        position: relative;

        &.center {
          justify-content: center;
        }

        @for $i from 1 through 20 {
          &.col-#{$i} { width: 1rem * $i };
        }

        .tag {
          font-size: 1.8rem;
          color: rgba(51, 161, 134, 1);
          transform: rotate(5deg);

          &.down {
            color: rgba(250, 99, 116, 1);
            transform: rotate(70deg);
          }
        }

        .i-more {
          padding: 1rem;
          cursor: pointer;
        }

        .edit-btns {
          position: absolute;
          top: 3rem;
          left: 0;
          z-index: 32;
          background-color: white;
          border-radius: .8rem;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          filter: drop-shadow(rgba(0,0,0,.08) 1.2rem 1.2rem 4rem);
          border: 1px solid rgba(12,55,103, 0.05);

          .btns {
            padding: .8rem 3rem;
            display: flex;
            align-items: center;
            transition: .3s ease;
            cursor: pointer;

            &:first-child {
              border-bottom: 1px solid #EDF2F7;
            }
            
            &.btn-edit:hover {
              background: rgba(241, 92, 72, 0.1);
              transition: .3s ease;
            }

            &.btn-del:hover {
              background: rgba(241, 92, 72, 0.1);
              transition: .3s ease;
            }

            i {
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      padding: 40px 0;
    }

    .list-foot {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 2rem;
      font-size: 1.7rem;
      border-top: 1px solid #E2E5E8;
      
      .foot-item {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }

        .num {
          color: #d62b2b;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
