/*
	状态对象
*/
export default {
	ab_url: process.env.VUE_APP_BASE_API, // 绝对路径前部
  upload_url: process.env.VUE_APP_UPLOAD_API, // 上传路径
	token: localStorage.getItem('token') || '',
  // 使用 JSON.parse() 要注意,其处理的对象不得为空,否则会报错,所以这里必须加上判断,如果值为空则不进行 JSON 转换
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
  fromList: [
    {
      id: 1,
      label: '味予餐饮（公司）'
    },
    {
      id: 2,
      label: '食为奴'
    },
    {
      id: 3,
      label: '美菜UU etc.'
    },
    {
      id: 4,
      label: '美团饿了么'
    },
    {
      id: 5,
      label: '淘宝京东'
    },
    {
      id: 6,
      label: '微信渠道'
    },
    {
      id: 7,
      label: '其他'
    }
  ],
}
