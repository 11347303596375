<template>
  <div class="not-found">
    <img src="../assets/img/404_01.svg" alt="">

    <div class="back-list">
      <input class="back" type="button" value="上一页" @click="$router.go('-1')">
      <input class="back" type="button" value="首页" @click="$router.push('/')">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    previousPage () {

    },

    homePage () {

    }
  }
}
</script>

<style lang="scss" scoped>
.not-found {
  color: red;
  font-size: 1.7rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  img {
    width: 50%;
    height: 50%;
    display: block;
  }

  .back-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;

    .back {
      padding: 1rem 2rem;
      border-radius: .8rem;
      cursor: pointer;

      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}
</style>
