/*
	直接更新 state 的多个方法的对象
*/
/* import {
} from './mutation-types'; */

export default {
	/* [GETSETTINGS] (state, {open_book, open_blog, open_video, open_topic}) {
		state.open_book = open_book
		state.open_blog = open_blog
		state.open_video = open_video
		state.open_topic = open_topic
	}, */

}
