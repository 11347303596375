<template>
  <div class="user-wrapper">
    <!-- <el-tabs :tab-position="'left'" style="height: 200px;">
      <el-tab-pane label="用户管理">用户管理</el-tab-pane>
      <el-tab-pane label="配置管理">配置管理</el-tab-pane>
      <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
    </el-tabs> -->

    <div class="backdrop transparent" v-if="showBackdrop" @click="removeAllBackItem"></div>

    <div class="profile-list">
      <ul>
        <li :class="{ on: onNumber == 1 }" @click="onNumber = 1">
          <span class="icon"><i class="iconfont icon-touxiang_avatar"></i></span>
          <div class="title-box">
            <span class="title">用户名</span>
            <span class="sub">修改用户名</span>
          </div>
        </li>
        
        <li :class="{ on: onNumber == 2 }" @click="onNumber = 2">
          <span class="icon"><i class="iconfont icon-newpassword"></i></span>
          <div class="title-box">
            <span class="title">密码</span>
            <span class="sub">修改密码</span>
          </div>
        </li>
        
        <li :class="{ on: onNumber == 3 }" @click="onNumber = 3">
          <span class="icon"><i class="iconfont icon-nickname"></i></span>
          <div class="title-box">
            <span class="title">头像</span>
            <span class="sub">修改头像</span>
          </div>
        </li>

        <li :class="{ on: onNumber == 4 }" @click="onNumber = 4">
          <span class="icon"><i class="iconfont icon-email2"></i></span>
          <div class="title-box">
            <span class="title">邮箱</span>
            <span class="sub">修改邮箱</span>
          </div>
        </li>

        <li :class="{ on: onNumber == 5 }" @click="onNumber = 5">
          <span class="icon"><i class="iconfont icon-ziyuan1"></i></span>
          <div class="title-box">
            <span class="title">资源</span>
            <span class="sub">上传的图片资源</span>
          </div>
        </li>
      </ul>
    </div>

    <div class="profile-content">
      <ul>
        <li class="profile-hide" :class="{ on: onNumber == 1 }">
          <h1 class="title">修改用户名</h1>
          
          <div class="form">
            <label for="">用户名</label>
            <input class="item" type="text" v-model="username" :placeholder="newUsername" @keyup.enter="save(0)">
            <span class="tip" v-if="usernameTip">{{ usernameTipText }}</span>

            <div class="save-btn">
              <input @click="save(0)" class="save" type="button" value="保存">
            </div>
          </div>
        </li>
        
        <li class="profile-hide" :class="{ on: onNumber == 2 }">
          <h1 class="title">修改密码</h1>

          <div class="form">
            <label for="">密码</label>
            <input class="item" type="password" v-model="password" placeholder="请输入新密码" @keyup.enter="save(1)">
            <label for="">重复密码</label>
            <input class="item" type="password" v-model="repassword" autocomplete="new-password" placeholder="请再次输入新密码" @keyup.enter="save(1)">
            <span class="tip" v-if="passwordTip">{{ passwordTipText }}</span>

            <div class="save-btn">
              <input @click="save(1)" class="save" type="button" value="保存">
            </div>
          </div>
        </li>
        
        <li class="profile-hide" :class="{ on: onNumber == 3 }">
          <h1 class="title">修改头像</h1>

          <div class="form">
            <div class="avatar">
              <img :src="avatar" alt="">
              
              <el-upload
                class="upload-demo"
                ref="avatarRef"
                accept=".jpg,.jpeg,.png,.gif"
                :action="upload_url"
                :auto-upload="false"
                :on-preview="handlePreview"
                :on-success="handlePreview"
                :on-remove="handleRemove"
                :on-change="handleChange"
                :file-list="fileList"
                multiple
                :limit="1"
                :on-exceed="onExceed">
                <i class="iconfont icon-edit2 edit"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png/gif文件，且不超过500kb</div>
              </el-upload>
            </div>

            <div class="save-btn">
              <input @click="submitUpload" class="save" type="button" value="保存">
            </div>
          </div>
        </li>

        <li class="profile-hide" :class="{ on: onNumber == 4 }">
          <h1 class="title">修改邮箱</h1>

          <div class="form">
            <label for="">邮箱 <span class="intro">(用于找回密码,建议保存为常用邮箱)</span></label>
            
            <!-- <input class="item" type="email" minlength="3" maxlength="64" v-model="email" :placeholder="emailPlaceholder" @keyup.enter="save(4)" disabled="disabled"> -->
            
            <span class="item">{{ emailPlaceholder }}</span>

            <div class="save-btn">
              <input @click="modifyEmailBtn" class="save" type="button" value="修改">
            </div>
          </div>

          <!-- 发送旧邮箱验证码 -->
          <div class="animate__animated animate__bounceInDown faster email-next" v-if="showEditEmail">
            <div class="intro">
              <span>验证码将发送到 {{ emailPlaceholder }} 邮箱</span>
              <span class="sub">如果长时间未收到验证码，请检查垃圾箱</span>
            </div>

            <div class="code-wrapper">
              <label for="">填写验证码：</label>

              <div class="code-text">
                <input class="input-code" type="text" v-model="oldEmailCode">
                <span class="get-code" @click="getOldCode" v-if="!isSendOldCode">发送验证码</span>
                <span class="get-code" v-else>{{ oldTime }}</span>
                <span class="tip" v-if="emailTip">{{ emailTipText }}</span>
              </div>
              
              <div class="btn-list">
                <input class="btn cancel" type="button" value="取消" @click="removeAllBackItem">
                <input class="btn next-step" type="button" value="下一步" @click="editNewEmail">
              </div>
            </div>
          </div>

          <!-- 填写新邮箱 -->
          <div class="animate__animated animate__bounceInDown faster form new-form" v-if="showNewEmail">
            <label for="">填写新邮箱</label>
            <input class="item" type="email" minlength="3" maxlength="64" v-model="newEmail" :placeholder="newEmailPlaceholder" @keyup.enter="save(4)">
            
            <div class="getNew-box">
              <input class="new-input" type="text" v-model="newEmailCode">
              <span class="sendNewCode" @click="getNewCode" v-if="!isSendNewCode">发送验证码</span>
              <span class="sendNewCode" v-else>{{ newTime }}</span>
            </div>

            <span class="tip" v-if="newEmailTip">{{ newEmailTipText }}</span>

            <div class="save-btn">
              <input class="save new" type="button" value="取消" @click="removeAllBackItem">
              <input class="save new" type="button" value="保存" @click="saveNewEmail">
            </div>
          </div>
        </li>

        <li class="profile-hide" :class="{ on: onNumber == 5 }">
          <h1 class="title">资源列表</h1>

          <div class="img-list" v-if="imglist.length > 0">
            <div class="item" v-for="(img, index) in imglist" :key="index">
              <img :src="ab_url + img.imgurl" alt="" @mouseenter="showDelIndex = index">
              
              <i class="iconfont icon-zu10kaobei2 major" v-if="img.imgurl.replace(ab_url, '') == user.avatar"></i>

              <div class="setlist" v-show="showDelIndex == index" @mouseleave="showDelIndex = -1">
                <span class="animate__animated animate__fadeIn faster del" @click="setAvatarImg(img.id, img?.imgurl)"><i class="iconfont icon-tupian"></i></span>

                <span class="animate__animated animate__fadeIn faster del" @click="deleteImg(img.id, img?.imgurl)"><i class="iconfont icon-delete2"></i></span>
              </div>
            </div>
          </div>

          <div class="nodata" v-else>暂无数据...</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      time: 60,
      oldTime: '发送验证码',
      newTime: '发送验证码',
      timer: null,
      showBackdrop: false,
      onNumber: 1,
      username: '',
      password: '',
      repassword: '',
      avatar: '',
      email: '',
      emailPlaceholder: '',
      /* fileList: [
        {
          name: 'food.jpeg',
          // url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        },
        {
          name: 'food2.jpeg',
          url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ] */
      fileList: [],
      newUsername: JSON.parse(localStorage.getItem('user')).username || '',
      setFlag: false, // 修改标记,防止重复修改
      usernameTip: false,
      usernameTipText: '',
      passwordTip: false,
      passwordTipText: '',
      emailTip: '',
      emailTipText: '',
      showEditEmail: false,
      showNewEmail: false,
      newEmail: '',
      newEmailPlaceholder: '',
      newEmailTip: false,
      newEmailTipText: '',
      oldEmailCode: '', // 旧邮箱验证码
      isSendOldCode: false, // 是否已发送了旧邮箱验证码
      newEmailCode: '', // 新邮箱验证码
      isSendNewCode: false, // 是否已发送了新邮箱验证码
      showDelIndex: -1, // 显示删除按钮
      imglist: [], // 图片列表
    }
  },

  methods: {
    // 封装提示方法
    alertMessage (type, message) {
      this.$message({
        type,
        message
      })
    },

    init () {
      this.avatar = this.ab_url + this.user.avatar;
      this.email = this.user.email;

      // 显示邮箱略写
      this.calcEmailPlaceholder();
      // 设置邮箱验证码自动过期
      this.setExpiration();
    },

    save (index) {
      if (this.setFlag) return;
      this.setFlag = true;

      // 用户名校验
      if (index == 0) {
        // 用户名不得重复
        if (this.username.trim() == this.user.username) {
          this.alertMessage('warning', '用户名不能相同!')
          return;
        }

        // 用户名不得为空
        if (this.username.trim().length == 0) {
          this.usernameTip = true;
          this.usernameTipText = '请输入新用户名！';
          this.setFlag = false;

          return;
        }

        // 用户名至少由字母、数字、下划线中两种组成,数字不能作为开头,6~16 位
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[A-Za-z_][A-Za-z_0-9]{5,16}$/g;
        if (!reg.test(this.username.trim())) {
          this.usernameTip = true;
          this.usernameTipText = '* 用户名至少由字母、数字、下划线中两种组成，数字不能作为开头，且5~16位！';
          this.setFlag = false;

          return;
        }
      }

      // 密码校验
      if (index == 1) {
        // 密码不得重复
        if (this.password.trim() == this.user.password) {
          this.alertMessage('warning', '密码不能相同!')
          return;
        }

        // 新密码不得为空
        if (this.password.trim().length == 0 || this.repassword.trim().length == 0) {
          this.passwordTip = true;
          this.passwordTipText = '请输入新密码！';
          this.setFlag = false;

          return;
        }

        // 密码至少由字母、数字、下划线中两种组成,数字不能作为开头,且不超过 16 位
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[A-Za-z_][A-Za-z_0-9]{5,16}$/g;
        if (!reg.test(this.password.trim())) {
          this.passwordTip = true;
          this.passwordTipText = '密码至少由字母、数字、下划线中两种组成，数字不能作为开头，且5~16位';
          this.setFlag = false;

          return;
        }

        // 新密码和重复密码要一致
        if (this.password != this.repassword) {
          this.passwordTip = true;
          this.passwordTipText = '再次密码输入不一致！';
          this.setFlag = false;
          
          return;
        }
      }

      // 修改用户名或密码后需要重新登录
      if (index == 0 || index == 1) {
        this.$confirm('修改后需要重新登录，确定要修改吗？', '修改', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then (res => {
          // 修改 username
          if (index == 0) {
            request.put('/user/1', {
              username: this.username
            })
          }
  
          // 修改 password
          if (index == 1) {
            request.put('/user/1', {
              password: this.password
            })
          }
  
          this.$message({
            type: 'success',
            message: '修改成功！',
            onClose: () => {
              this.setFlag = false;
              this.usernameTip = false;
              this.passwordTip = false;
              let reg = /^(?!^[0-9]*$)^([a-z0-9]{8,16}$)/g;
  
              // 退出登录
              localStorage.setItem('token', '');
              localStorage.setItem('user', '');
              this.$router.push('/login');
  
              // location.reload();
            }
          })
        })
        .catch(() => {
          this.setFlag = false;
          // this.alertMessage('info', '已取消！');
        })
      }
    },

    // upload 上传
    submitUpload() {
      this.$refs.avatarRef.submit();
    },
    // 上传文件超出限制
    onExceed (file, fileList) {
      this.alertMessage('info', '目前只支持1个文件上传！');
    },
    handleChange (file, fileList) {
      this.fileList = fileList;
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePreview(response, file, fileList) {
      console.log(file);
      console.log(fileList);
      let avatarUrl = response.data.file;
      
      // 更新缓存
      let user = JSON.parse(localStorage.getItem('user')); // 获取 user 原缓存
      // let avatarUrl = this.avatar.match(/\/uploads(\S*)/gi)[0];
      user.avatar = avatarUrl; // 修改其中 avatar 的值
      localStorage.setItem('user', JSON.stringify(user)); // 再次缓存
      // 改变全局 user 的值以更新全部头像
      this.$store.state.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
      this.avatar = this.ab_url + avatarUrl;

      // 修改数据库中的 avatar
      request.put('/userProfile/1', {
        avatar: avatarUrl
      })
      .then (() => {
        this.alertMessage('success', '保存成功！')
      })

      // 存储新上传的图片
      request.post('/uplist', {
        imgurl: avatarUrl
      })
      .then (() => {
        this.getImgList();
      })
    },

    // 计算隐藏后的 email 长度及添加星号
    calcEmailPlaceholder () {
      let email = JSON.parse(localStorage.getItem('user')).email;
      let emailFront = email.match(/(\S*)@/)[1];
      this.emailPlaceholder = emailFront.slice(0, 2) + new Array(emailFront.length - 2).fill('*').toString().replace(/,/g, '') + "@" + email.match(/@(\S*)/)[1];
    },

    /**
     * 修改邮箱
    */
    // 触发修改邮箱
    modifyEmailBtn () {
      // 首先显示修改框和防点击背景
      this.showEditEmail = true;
      this.showBackdrop = true;
    },
    // 获取旧邮箱验证码
    getOldCode () {
      request.post('/email', {
        username: this.user.username,
        email: this.email,
      })
      .then (res => {
        if (res.data.message) {
          // 获取从后台返回的邮箱验证码
          let resOldCode = res.data.code;
          // 设置过期时间为 10 分钟
          let oldExpirationTime = new Date().getTime() + 10 * 60 * 1000;
          sessionStorage.setItem('oldExpirationTime', oldExpirationTime);
          sessionStorage.setItem('resOldCode', resOldCode);

          // 显示倒计时秒数
          this.isSendOldCode = true;

          // 倒计时
          this.timer = setInterval(() => {
            this.time--;
            this.oldTime = this.time;
    
            if (this.time == 0) {
              clearInterval(this.timer);
              this.oldTime = '发送验证码';
            }
          }, 1000);
        }
        else {
          this.$message({
            type: 'error',
            message: '验证码获取失败！'
          })
        }
      })
    },
    // 为验证码设置自动过期
    setExpiration () {
      if (new Date().getTime() > sessionStorage.getItem('oldExpirationTime')) {
        sessionStorage.removeItem('oldExpirationTime');
        sessionStorage.removeItem('resOldCode');
      }
      
      if (new Date().getTime() > sessionStorage.getItem('newExpirationTime')) {
        sessionStorage.removeItem('newExpirationTime');
        sessionStorage.removeItem('resNewCode');
      }
    },
    // 下一步,编辑新邮箱
    editNewEmail () {
      /**
       * 校验验证码
       */
      // 旧邮箱验证码不得为空
      if (!this.oldEmailCode.trim()) {
        this.emailTip = true;
        this.emailTipText = '验证码不能为空！';
        return;
      }

      // 验证码已过期
      if (new Date().getTime() > sessionStorage.getItem('oldExpirationTime')) {
        sessionStorage.removeItem('oldExpirationTime');
        sessionStorage.removeItem('resOldCode');

        this.emailTip = true;
        this.emailTipText = '验证码已过期，请重新获取！';
        return;
      }

      // 旧邮箱验证码与填写的不符
      if (sessionStorage.getItem('resOldCode') && this.oldEmailCode != sessionStorage.getItem('resOldCode')) {
        this.emailTip = true;
        this.emailTipText = '验证码错误！';
        return;
      }

      // 清理定时器及邮箱状态
      this.removeAllBackItem();

      // 打开新邮箱编辑框和背景
      this.showNewEmail = true;
      this.showBackdrop = true;
    },
    // 获取新邮箱验证码
    getNewCode () {
      // 邮箱不得重复
      if (this.newEmail.trim() == this.user.email) {
        // this.alertMessage('warning', '该邮箱已存在!');
        this.newEmailTip = true;
        this.newEmailTipText = '该邮箱已存在！';
        return;
      }

      // 校验邮箱
      let emailReg = /^\w{3,}(\.\w+)*@[a-z0-9]+(\.[a-z]{2,5}){1,2}$/;
      if (!emailReg.test(this.newEmail)) {
        this.newEmailTip = true;
        this.newEmailTipText = '邮箱不合法!';
        return;
      }
      else {
        // 更新提示状态
        this.newEmailTip = false;
        this.newEmailTipText = '';

        // 如果检验是邮箱,则获取新邮箱验证码
        request.post('/email', {
          username: this.user.username,
          email: this.newEmail,
        })
        .then (res => {
          if (res.data.message) {
            // 获取从后台返回的邮箱验证码
            let resNewCode = res.data.code;
            // 设置过期时间为 10 分钟
            let oldExpirationTime = new Date().getTime() + 10 * 60 * 1000;
            sessionStorage.setItem('newExpirationTime', oldExpirationTime);
            sessionStorage.setItem('resNewCode', resNewCode);

            // 显示倒计时秒数
            this.isSendNewCode = true;

            // 倒计时
            this.timer = setInterval(() => {
              this.time--;
              this.newTime = this.time;
      
              if (this.time == 0) {
                clearInterval(this.timer);
                this.newTime = '发送验证码';
              }
            }, 1000);
          }
          else {
            this.$message({
              type: 'error',
              message: '验证码获取失败！'
            })
          }
        })
      }
    },
    // 点击防点击背景,隐藏所有显示框
    removeAllBackItem () {
      clearInterval(this.timer);
      this.time = 60;

      this.isSendOldCode = false;
      this.isSendNewCode = false;
      this.oldEmailCode = '';
      this.newEmailCode = '';
      this.showEditEmail = false; // 隐藏修改邮箱
      this.showNewEmail = false; // 隐藏填写新邮箱
      
      // 新旧邮箱验证码立即失效
      sessionStorage.removeItem('oldExpirationTime');
      sessionStorage.removeItem('resOldCode');
      sessionStorage.removeItem('newExpirationTime');
      sessionStorage.removeItem('resNewCode');

      this.showBackdrop = false; // 隐藏背景
    },
    // 保存新邮箱
    saveNewEmail () {
      // 新邮箱不得为空
      if (!this.newEmail.trim()) {
        this.newEmailTip = true;
        this.newEmailTipText = '请填写新邮箱！';
        return;
      }

      // 新邮箱必须合法
      let newEmailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/gi;
      if (!newEmailReg.test(this.newEmail.trim())) {
        this.newEmailTip = true;
        this.newEmailTipText = '邮箱格式不合法！';
        return;
      }

      // 邮箱验证码不得为空
      if (!this.newEmailCode.trim()) {
        this.newEmailTip = true;
        this.newEmailTipText = '验证码不能为空！';
        return;
      }

      // 验证码已过期
      if (new Date().getTime() > sessionStorage.getItem('newExpirationTime')) {
        sessionStorage.removeItem('newExpirationTime');
        sessionStorage.removeItem('resNewCode');

        this.newEmailTip = true;
        this.newEmailTipText = '验证码已过期，请重新获取！';
        return;
      }

      // 旧邮箱验证码与填写的不符
      if (sessionStorage.getItem('resNewCode') && this.newEmailCode != sessionStorage.getItem('resNewCode')) {
        this.newEmailTip = true;
        this.newEmailTipText = '验证码错误！';
        return;
      }

      // 清理定时器及邮箱状态
      this.removeAllBackItem();

      // 修改邮箱
      request.put(`/userProfile/${ this.user.id }`, {
        email: this.newEmail
      })
      .then (res => {
        if (res.data.message) {
          // 修改显示邮箱
          this.emailPlaceholder = this.newEmail;
          
          // 修改缓存
          let user = JSON.parse(localStorage.getItem('user')); // 获取 user 原缓存
          user.email = this.newEmail; // 修改其中 email 的值
          localStorage.setItem('user', JSON.stringify(user)); // 再次缓存

          this.alertMessage('success', '修改成功！');
        }
        else {
          this.alertMessage('error', '修改失败！');
        }
      })
    },

    // 获取图片列表
    getImgList () {
      request.get(`/uplist`)
      .then (res => {
        if (!!res.data && res.data.message) {
          let data = res.data?.data?.rows;

          if (data?.length > 0) {
            // data.map(i => i.imgurl = this.ab_url + i.imgurl);
            this.imglist = data;
          }
        }
      })
    },

    // 设为头像
    setAvatarImg (id, imgurl) {
      // 如果当前要设置的图片是当前的头像,不支持重复设置
      // let _imgurl = imgurl.replace(this.ab_url, '');
      if (this.user.avatar == imgurl) {
        this.alertMessage('info', '当前图片已经是头像！');
        return;
      }

      this.$confirm('将此图设为头像？', '头像', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then (() => {
        // 修改数据库中的 avatar
        request.put('/userProfile/1', {
          avatar: imgurl
        })
        .then (() => {
          this.alertMessage('success', '设置成功！');

          // 更新缓存
          let user = JSON.parse(localStorage.getItem('user')); // 获取 user 原缓存
          user.avatar = imgurl; // 修改其中 avatar 的值
          localStorage.setItem('user', JSON.stringify(user)); // 再次缓存
          // 改变全局 user 的值以更新全部头像
          this.$store.state.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';

          // 更新头像修改中的头像显示
          this.avatar = this.ab_url + this.user.avatar;
        })
      })
      .catch(() => {})
    },

    // 删除图片资源
    deleteImg (id, imgurl) {
      if (this.imglist?.length == 1) {
        this.alertMessage('info', '当前图片仅有一个，不支持删除！');
        return;
      }

      // 如果当前要删除的图片是当前的头像,也不支持删除
      // if (this.user.avatar == imgurl.replace(this.ab_url, '')) {
      if (this.user.avatar == imgurl) {
        this.alertMessage('info', '当前图片为头像，不支持删除，请先修改头像！');
        return;
      }
      
      this.$confirm('确定删除吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then (() => {
        // 删除文件
        // let _imgurl = imgurl.replace(this.ab_url, '');

        request.post(`/updelete`, {
          file: imgurl
        })
        .then ((res) => {
          if (!!res.data && res.data.message) {
            request.delete(`/uplist/${ id }`)
            .then (() => {
              this.getImgList();

              this.alertMessage('info', '删除成功！');
            })
          }
        })
      })
      .catch (() => {})
    },
  },
  
  computed: {
    ...mapState(['ab_url', 'user', 'upload_url']),
  },

  mounted () {
    this.init(); // 启动默认项
  },

  watch: {
    // 查看资源时,加载资源列表
    onNumber (newval) {
      if (newval == 5) {
        this.getImgList();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.user-wrapper {
  display: flex;
  background-color: white;
  border-radius: 1.2rem;
  overflow: hidden;
  padding-top: 1rem;

  .profile-list {
    display: flex;
    flex-direction: column;
    width: 24rem;
    min-height: 70vh;
    padding: 1.5rem;
    border-right: 1px solid #EDF2F7;

    ul li {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: rgba(26, 32, 44, 1);
      padding: 1.5rem;
      margin-bottom: 1rem;
      border-radius: .8rem;
      cursor: pointer;
      transition: .3s ease;

      &:hover {
        background-color: #F7FAFC;
        transition: .3s ease;

        &.icon {
          background-color: #F7FAFC;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        width: 5rem;
        height: 5rem;
        border-radius: 5rem;
        background-color: #F7FAFC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
  
        i {
          font-size: 2.1rem;
        }
      }

      &.on {
        background-color: #F7FAFC;

        .icon {
          color: white;
          background-color: #22C55E;
        }
      }

      .title-box {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: bold;
          font-size: 1.5rem;
        }

        .sub {
          display: block;
          font-size: 1.3rem;
          margin-top: .5rem;
          color: rgba(74, 85, 104, 1);
          font-size: 1.3rem;
        }
      }
    }
  }

  .profile-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem 2rem 4rem;

    .profile-hide {
      display: none;
      width: 40rem;

      &.on {
        display: block;
      }

      &>.title {
        padding-bottom: 2.5rem;
        margin-bottom: 3rem;
        border-bottom: 1px solid #EDF2F7;
      }

      .form {
        color: rgba(113, 128, 150, 1);
        display: flex;
        flex-direction: column;

        .intro {
          font-size: 12px;
          color: #f15454;
        }

        .avatar {
          position: relative;

          img {
            width: 15rem;
            height: 15rem;
            border-radius: 10rem;
          }

          i {
            position: absolute;
            top: 10rem;
            left: 11.5rem;
            z-index: 2;
            font-size: 2.5rem;
            background: #22c55e;
            border-radius: 100%;
            color: white;
            padding: 0.5rem;
            cursor: pointer;
          }
        }

        .item {
          height: 5rem;
          margin-top: 1rem;
          margin-bottom: 3rem;
          border-radius: .8rem;
          border: 1px solid transparent;
          padding: 0 1.5rem;
          background-color: #f1f2f5;
          display: flex;
          align-items: center;

          &:focus {
            border-color: #22C55E;
          }
        }

        .save-btn {
          margin-left: auto;

          .save {
            border: none;
            background-color: #22C55E;
            width: 12rem;
            height: 5rem;
            border-radius: .8rem;
            font-size: 1.5rem;
            color: white;
            margin-top: 2rem;
            cursor: pointer;
            margin-left: 1rem;

            &.new {
              width: auto;
              height: auto;
              padding: 1rem 2rem;
            }
          }
        }

        &.new-form {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 32;
          margin: auto;
          display: flex;
          width: -moz-fit-content;
          width: fit-content;
          height: -moz-fit-content;
          height: fit-content;
          filter: drop-shadow(rgba(0, 0, 0, 0.08) 12px 12px 40px);
          background: white;
          padding: 5rem 4rem;
          border-radius: 0.8rem;

          .getNew-box {
            position: relative;

            .new-input {
              border-radius: 0.8rem;
              padding: 0 1.5rem;
              background: #F0FAFA;
              border: none;
              height: 5rem;
              padding-right: 9rem;
              border: 1px solid transparent;

              &:focus {
                border-color: #22C55E;
              }
            }

            .sendNewCode {
              position: absolute;
              right: 1rem;
              top: 1.5rem;
              cursor: pointer;
            }
          }
        }
      }

      .img-list {
        display: flex;
        flex-wrap: wrap;

        .item {
          margin-right: 1rem;
          margin-bottom: 1rem;
          position: relative;
          width: 8rem;
          height: 8rem;
          border-radius: 1rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
            cursor: pointer;
          }

          .major {
            position: absolute;
            top: -1rem;
            left: -1rem;
            transform: rotate(-15deg);
            z-index: 2;
            font-size: 2.5rem;
            color: #b7c32c;
          }

          .setlist {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .del {
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              width: 100%;
              height: 50%;

              &:first-child {
                border-radius: 1rem 1rem 0 0;
                background: rgba($color: #000000, $alpha: .3);
              }

              &:last-child {
                border-radius: 0 0 1rem 1rem;
                background: rgba($color: #000000, $alpha: .6);
              }

              i {
                font-size: 2rem;
              }
            }
          }
        }
      }

      .nodata {
        text-align: center;
        margin-top: 10rem;
        font-size: 2rem;
        font-weight: lighter;
      }
    }
  }
  
  .email-next {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 32;
    background-color: white;
    filter: drop-shadow(rgba(0, 0, 0, 0.08) 12px 12px 40px);
    margin: auto;
    width: fit-content;
    height: fit-content;
    padding: 5rem 4rem;
    border-radius: 0.8rem;

    .intro {
      display: flex;
      flex-direction: column;

      .sub {
        color: #999;
        font-size: 1.3rem;
        margin-top: .5rem;
        margin-bottom: 2rem;
      }
    }

    .code-wrapper {
      display: flex;
      flex-direction: column;

      .code-text {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        .input-code {
          flex: 1;
          border-radius: 0.6rem;
          padding: 1.5rem;
          padding-right: 9rem;
          border: 1px solid transparent;
          background-color: #EEF8F8;

          &:focus {
            border-color: #22C55E;
          }
        }

        .get-code {
          cursor: pointer;
          position: absolute;
          right: 1rem;
          top: 1.5rem;
          color: rgb(113, 128, 150);
        }
      }

      .btn-list {
        margin-left: auto;

        .btn {
          border: none;
          color: white;
          border-radius: .8rem;
          background-color: #22C55E;
          padding: 1rem 2rem;
          margin-top: 1rem;
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}

</style>
