<template>
  <div class="goods-list">
    <div class="backdrop transparent" v-if="showBackdrop" @click="removeAllBackItem"></div>

    <div class="title-box">
      <div class="title">
        <span class="text">Goods List</span>
        <span class="sub">All goods are here shown</span>
      </div>

      <div class="search-goods">
        <i class="iconfont icon-search3"></i>
        <input class="search-input" :class="{ focus: searchFocus }" type="text" v-model="searchGoods" placeholder="搜索商品名称..." :disabled="disabled" @focus="searchFocus = true" @blur="searchFocus = false" @keyup.enter="doSearchGoods">
      </div>

      <div class="btn-box">
        <div class="add-btn" @click="addOrEditGood(0)"><i class="iconfont icon-tianjia"></i> Add good</div>

        <el-select class="from-box" v-model="from.label" filterable clearable placeholder="From" :disabled="fromDisabled" @change="showGoodsListByFrom(from.label)" @clear="clearFrom">
          <el-option
            v-for="item in fromList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
            :disabled="disabled"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="goods-list-box">
      <div class="noData" v-if="showNoData"><i class="iconfont icon-a-UploadFailed"></i></div>
      
      <div class="list" v-for="(good, index) in goodsList" :key="index" v-else>
        <div class="item">
          <div class="title">Name</div>
          <div class="title-value">{{ good.name }}</div>
        </div>
        
        <div class="item">
          <div class="title">Desc</div>
          <div class="title-value">{{ good.desc }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Price</div>
          <div class="title-value">{{ good.price }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Speci</div>
          <div class="title-value">{{ good.speci }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Cost</div>
          <div class="title-value">{{ good.cost }}</div>
        </div>

        <div class="item limit06">
          <div class="title">From</div>
          <div class="title-value">{{ good.from?.label }}</div>
        </div>

        <div class="item">
          <div class="title">Remark</div>
          <div class="title-value">{{ good.remark }}</div>
        </div>

        <div class="item btns">
          <i class="iconfont icon-i-more action-more" @click="showGoodEditBtnFn(index)"></i>
          <div class="animate__animated animate__bounceIn faster item-action" v-if="goodEditBtnIndex == index">
            <span class="text" @click="addOrEditGood(good.id)"><i class="iconfont icon-bianjibiaoge_o"></i> edit</span>
            <span class="text" @click="deleteGood(good.id, index)"><i class="iconfont icon-icondelete"></i> delete</span>
          </div>
        </div>
      </div>

      <div class="pagination-filters">
        <div class="show-list">
          <el-dropdown trigger="click" type="primary">
            <span class="el-dropdown-link">
              Show result<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in pageDropdownList" :key="item" @click.native="handleSizeChange(item)">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
    </div>

    <!-- 新增/修改 -->
    <div class="animate__animated animate__bounceInDown faster edit-good" ref="editGoodBox" v-if="showGoodEditBox">
      <div class="item">
        <span class="title">Name</span>
        <input class="input" type="text" v-model="active.name" placeholder="请输入商品名称">
      </div>

      <div class="item">
        <span class="title">Desc</span>
        <input class="input" type="text" v-model="active.desc" placeholder="请输入商品描述">
      </div>

      <div class="item">
        <span class="title">Price</span>
        <input class="no-spin input" type="number" v-model="active.price" placeholder="请输入商品价格">
      </div>

      <div class="item">
        <span class="title">Speci</span>
        <input class="input" type="text" v-model="active.speci" placeholder="请输入商品规格">
      </div>

      <div class="item">
        <span class="title">Cost</span>
        <input class="no-spin input" type="number" v-model="active.cost" placeholder="请输入最小单位成本">
      </div>

      <div class="item">
        <span class="title">From</span>

        <el-select v-model="active.from" filterable placeholder="请选择供应渠道">
          <el-option
            v-for="item in fromList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      
      <div class="item">
        <span class="title">Remark</span>
        <input class="input" type="text" v-model="active.remark" maxlength="100" clearable placeholder="请输入备注">
      </div>
      
      <div class="item">
        <span class="title"></span>
        <button class="button cancel" @click="cancelEdit">取消</button>
        <button class="button save" :class="{ abandon: isAbandon > 0 }" @click.prevent="saveEdit">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      timer: null,
      showNoData: false, // 暂无数据
      goodsList: [],
      active: {
        id: 0, // 当前操作的商品项 id
        name: '',
        desc: '',
        price: '',
        speci: '',
        cost: '',
        from: '',
        remark: '',
      },
      from: {
        id: null,
        label: ''
      }, // 选择渠道显示列表
      showBackdrop: false, // 背景
      showGoodEditBtn: false, // 显示商品编辑栏目
      goodEditBtnIndex: -1, // 显示当前商品编辑栏目
      showGoodEditBox: false, // 显示编辑商品框
      fromDisabled: false, // 是否禁止选择渠道
      pageDropdownList: [5, 10, 15, 20, 30, 50, 100],
      currentPage: 1, // 当前页默认为第一页
      pageSize: 30, // 每页显示条数
      total: 0, // 列表总数
      searchGoods: '', // 搜索商品
      searchFocus: false, // 搜索 focus 效果
      disabled: false,
      isAbandon: 0, // 防止双击标识
      fetchNum: 0,
    }
  },

  computed: {
    ...mapState(['fromList']),
  },

  methods: {
    // 初始化
    init () {
      clearTimeout(this.timer);
      this.getGoodsList();
    },

    // 自定义提示
    messageAlert (type, message, duration=3000, closeCallback) {
      this.$message({
        type,
        message,
        duration,
        onClose: closeCallback && closeCallback()
      })
    },

    // 获取商品列表
    getGoodsList (goodsParams) {
      if (this.fetchNum > 0) return;

      let _params = { page: this.currentPage, countPerPage: this.pageSize };
      if (goodsParams) {
        Object.assign(_params, goodsParams);
      }

      request.get(`/goods`, { params: _params })
      .then (res => {
        if (!!res.data && res.data.message) {
          let result = res.data?.data
          let data = result?.rows;
          let count = result?.count;

          // 如果列表为空
          if (!data || data?.length == 0) {
            this.showNoData = true;
            return;
          }

          if (data) {
            data.map(i => {
              i.price = Number(i.price).toFixed(2);
              i.cost = i.cost ? Number(i.cost).toFixed(2) : '';
            })
  
            // 列表赋值
            this.goodsList = data;
            // 页码
            this.total = count;
  
            // 放开禁用
            this.showNoData = false;
            this.disabled = false;
            this.fromDisabled = false;
            this.showBackdrop = false;
          }
        }
        else {
          this.messageAlert('info', '数据加载失败！');
          this.showNoData = true;
          return;
        }
      })
    },

    // 移除所有背景和显示框
    removeAllBackItem () {
      // 如果编辑栏目已显示,则点击背景时隐藏它
      if (this.goodEditBtnIndex > -1) {
        this.showBackdrop = false;
      }

      // 隐藏编辑栏目
      this.goodEditBtnIndex = -1;
    },

    // 显示商品编辑框
    showGoodEditBtnFn (index) {
      this.goodEditBtnIndex = index; // 显示当前商品编辑栏
      this.showBackdrop = true;
    },
    
    // 取消编辑
    cancelEdit () {
      this.goodEditBtnIndex = -1; // 隐藏编辑栏

      if (this.$refs.editGoodBox) {
        this.$refs.editGoodBox.classList.remove('animate__bounceInDown');
        this.$refs.editGoodBox.classList.add('animate__zoomOutDown');
      }

      this.timer = setTimeout(() => {
        this.showGoodEditBox = false;
        this.showBackdrop = false;
      }, 300);
    },
    
    // 新增/编辑商品
    addOrEditGood (id) {
      // 如果 id 存在,则是修改
      if (id) {
        // 调整当前操作的商品项 id
        this.$set(this.active, 'id', id);

        // 获取商品相应的属性
        let curGood = this.goodsList.filter(i => i.id == id);

        this.$set(this.active, 'name', curGood[0].name);
        this.$set(this.active, 'desc', curGood[0].desc);
        this.$set(this.active, 'price', Number(curGood[0].price).toFixed(2));
        this.$set(this.active, 'speci', curGood[0].speci);
        this.$set(this.active, 'cost', Number(curGood[0].cost).toFixed(2));
        this.$set(this.active, 'from', curGood[0].from.id);
        this.$set(this.active, 'remark', curGood[0].remark);
      }
      // 否则是新增
      else {
        this.$set(this.active, 'id', '');
        this.$set(this.active, 'name', '');
        this.$set(this.active, 'desc', '');
        this.$set(this.active, 'price', '');
        this.$set(this.active, 'speci', '');
        this.$set(this.active, 'cost', '');
        this.$set(this.active, 'from', '');
        this.$set(this.active, 'remark', '');
      }

      this.goodEditBtnIndex = -1; // 隐藏当前商品编辑栏
      this.showBackdrop = true; // 显示背景
      this.showGoodEditBox = true; // 显示商品编辑框
    },

    // 保存编辑
    saveEdit () {
      // 不允许双击
      if (this.isAbandon > 0) {
        return;
      }

      if (!this.active.name) {
        this.messageAlert('info', '请填写商品名称！');
        return;
      }
      
      if (!this.active.from) {
        this.messageAlert('info', '请填写商品渠道来源！');
        return;
      }

      this.isAbandon++;

      // 将 from 转换为对应的数字
      let fromArr = this.fromList.filter(i => i.id == this.active.from);
      let fromId = fromArr[0]?.id;
      // 当前 from 的文字
      let fromLabel = fromArr[0]?.label;

      // let fromId = this.active.from.id;
      let current = {
        name: this.active.name,
        desc: this.active.desc,
        price: Number(this.active.price).toFixed(2),
        speci: this.active.speci,
        cost: this.active.cost,
        from: fromId,
        remark: this.active.remark,
      }
      
      // 如果 id 存在即为修改
      let curId = this.active?.id;
      if (!!curId) {
        request.put(`/goods/${ curId }`, current)
        .then (res => {
          if (res.data.message) {
            this.messageAlert('success', '修改成功！');
            this.cancelEdit();

            // 更新列表
            this.getGoodsList();
            
            this.timer = setTimeout(() => {
              this.isAbandon--;
            }, 1000);
          }
          else {
            this.messageAlert('success', '修改失败！');
          }
        })

        return;
      }

      // 否则 新增商品
      request.post(`/goods`, current)
      .then (res => {
        if (res.data.message) {
          this.messageAlert('success', '新增成功！', 1000, () => {
            this.cancelEdit();
            this.currentPage = 1;
            this.getGoodsList();

            this.timer = setTimeout(() => {
              this.isAbandon--;
            }, 1000);
          });
        }
        else {
          this.messageAlert('success', '新增失败！');
        }
      });
    },
    
    // 删除商品
    deleteGood (id, index) {
      this.$confirm('删除后，此信息在回收站仅保存 7 天', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then (() => {
        request.delete(`/goods/${ id }`)
        .then (res => {
          if (res.data.message) {
            this.messageAlert('success', '删除成功！');

            // 只存在渠道,不存在搜索
            if (this.from.label > 0 && !this.searchGoods) {
              this.getGoodsList({ from: this.from.label });
            }

            // 只存在搜索,不存在渠道
            if (!this.from.label && this.searchGoods) {
              let name = this.searchGoods;
              this.getGoodsList({ name });
            }

            // 存在渠道和搜索
            if (this.from.label > 0 && this.searchGoods) {
              let name = this.searchGoods.trim();
              this.getGoodsList({ name, from: this.from.label });
            }

            // 不存在渠道和搜索
            if (!this.from.label && !this.searchGoods) {
              this.getGoodsList();
            }

            // 列表为空
            if (this.goodsList.length == 0) {
              this.total = 0;
              this.showNoData = true;
              this.disabled = true;
              this.fromDisabled = true;
            }
          }
          else {
            this.messageAlert('success', '删除失败！');
          }

          this.cancelEdit();
        })
      })
      .catch (() => {
        this.cancelEdit();
      })
    },

    // 根据渠道展示商品列表
    showGoodsListByFrom (fromId) {
      this.fetchNum = 0; // 恢复渠道请求
      this.currentPage = 1;

      // 如果存在搜索内容,再按搜索内容显示列表
      let params = this.searchGoods ? {
        name: this.searchGoods,
        from: fromId,
      } : {
        from: fromId,
      };
      
      this.getGoodsList(params);
    },

    // 清空渠道
    clearFrom () {
      this.currentPage = 1;
      this.searchGoods = this.searchGoods ? this.searchGoods.trim() : '';

      // 如果存在搜索内容
      let params = this.searchGoods ? { name: this.searchGoods } : {};
      
      this.fetchNum++; // 禁止渠道重复请求
      this.getGoodsList(params);
    },

    // 切换每页条数
    handleSizeChange (size) {
      this.currentPage = 1; // 页面回归第一页
      this.pageSize = size; // 更改条数

      let params = {};
      if (this.searchGoods) {
        this.searchGoods = this.searchGoods.trim();

        params = this.from.label ? {
          name: this.searchGoods,
          from: this.from.label
        } : {
          name: this.searchGoods
        }
      }
      else {
        if (this.from.label) {
          params = {
            from: this.from.label
          }
        }
      }

      this.getGoodsList(params);
    },

    // 切换页码
    handleCurrentChange (val) {
      this.currentPage = this.currentPage == 1 ? this.currentPage : val;

      let params = {};
      if (this.searchGoods) {
        this.searchGoods = this.searchGoods.trim();

        params = this.from.label ? {
          name: this.searchGoods,
          from: this.from.label
        } : {
          name: this.searchGoods
        }
      }
      else {
        if (this.from.label) {
          params = {
            from: this.from.label
          }
        }
      }

      this.getGoodsList(params);
    },

    // 搜索
    doSearchGoods () {
      this.searchGoods = this.searchGoods.trim();

      if (!this.searchGoods && this.from?.label == 0) return;

      // 页码回归首页
      this.currentPage = 1;

      let params = {};
      // 如果有搜索内容
      if (this.searchGoods) {
        // 如果有渠道条件
        params = this.from?.label > 0 ? {
          name: this.searchGoods,
          from: this.from.label
        } : {
          name: this.searchGoods
        };
      }
      // 没有搜索内容
      else {
        // 如果有渠道条件
        params = this.from?.label > 0 ? {
          from: this.from.label
        } : {};
      }

      this.getGoodsList(params);
    },
  },

  mounted () {
    this.init(); // 初始化
    clearTimeout(this.timer);
  },

  watch: {
    searchGoods (newval) {
      if (!newval || newval.length == 0) {
        this.fetchNum = 0; // 恢复渠道请求

        let params = {};
        // 如果有搜索内容
        if (this.searchGoods) {
          // 如果有渠道条件
          params = this.from?.label > 0 ? {
            name: this.searchGoods,
            from: this.from.label
          } : {
            name: this.searchGoods
          };
        }
        // 没有搜索内容
        else {
          // 如果有渠道条件
          params = this.from?.label > 0 ? {
            from: this.from.label
          } : {};
        }

        this.getGoodsList(params);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.abandon {
  pointer-events: none;
}

.goods-list {
  padding: 4rem;
  background-color: white;
  border-radius: $borderRadius08;
  // overflow: hidden;

  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;

    .title {
      display: flex;
      flex-direction: column;

      .text {
        font-size: 2rem;
        font-weight: bold;
      }

      .sub {
        font-size: 1.2rem;
        color: #999;
      }
    }

    .search-goods {
      min-width: 40rem;
      display: flex;
      align-items: center;
      position: relative;

      i {
        font-size: 2.2rem;
        position: absolute;
        left: 1rem;
      }

      input {
        flex: 1;
        border: none;
        background-color: #f1f2f5;
        padding: 1.5rem 2.5rem;
        border-radius: $borderRadius08;
        border: 1px solid transparent;
        font-size: 1.4rem;
        text-indent: 1.5rem;

        &.focus {
          border-color: #22C55E;
        }
      }
    }

    .btn-box {
      display: flex;

      .add-btn {
        margin-right: 1rem;
        border-radius: .8rem;
        background-color: #22C55E;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        cursor: pointer;
        transition: .2s ease;

        &:hover {
          background-color: #30DC2D;
          transition: .2s ease;
        }

        i {
          margin-right: .5rem;
        }
      }

      .from-box {
        width: 15rem;
      }
    }
  }

  .goods-list-box {
    width: calc(100% + 2rem);
    margin-left: -1rem;

    .list {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      padding: 1rem;
      transition: .25s ease;

      &:hover {
        background-color: #F5F7FA;
        transition: .25s ease;
      }

      .item {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.limit06 {
          flex: .6;
        }

        &.btns {
          flex: unset;
        }
        
        .title {
          margin-bottom: 1rem;
          color: darkgrey;
          font-size: 1.35rem;
          margin-bottom: auto;
        }

        .title-value {
          font-size: 1.5rem;
          margin-bottom: auto;
        }

        .action-more {
          cursor: pointer;
          padding: 1rem;
        }

        .item-action {
          position: absolute;
          top: 5rem;
          right: 0;
          z-index: 31;
          border-radius: .8rem;
          filter: drop-shadow(rgba(0, 0, 0, 0.08) 12px 12px 40px);
          overflow: hidden;

          .text {
            cursor: pointer;
            padding: 2rem 6rem;
            text-align: center;
            background-color: white;
            transition: .3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #EDF2F7;
  
            &:last-child {
              border-bottom: 1px solid transparent;
            }

            &:hover {
              color: white;
              background-color: #22C55E;
              transition: .3s ease;
            }

            i {
              margin-right: .5rem;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  .edit-good {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 32;
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: 5rem;
    border-radius: $borderRadius08;
    background: white;
    filter: drop-shadow(rgba(0, 0, 0, 0.08) 12px 12px 40px);

    .item {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;

      .title {
        font-size: 1.5rem;
        margin-right: 1rem;
        min-width: 10rem;
      }
      
      .input {
        background-color: #F0FAFA;
        padding: 2rem 1rem;
        border-radius: .8rem;
        text-indent: 1rem;
        border: 1px solid transparent;
        min-width: 30rem;

        &:focus {
          border-color: #22C55E;
        }
      }

      .dropdown {
        border: 1px solid #E2E8F0;
        border-radius: .8rem;
        background: #F0FAFA;
        cursor: pointer;

        .el-dropdown-link {
          padding: 1.85rem 2.5rem;
          display: block;
        }
      }

      .button {
        width: auto;
        height: auto;
        padding: 1.5rem 3rem;
        border-radius: $borderRadius08;
        border: 1px solid transparent;
        color: white;
        font-size: 1.4rem;
        margin-right: 2rem;
        background-color: #22C55E;
        cursor: pointer;
        transition: .3s ease;

        &.save:hover {
          background-color: #16A34A;
          transition: .3s ease;
        }

        &.cancel {
          background-color: #D9FBE6;
          color: rgba(34, 197, 94, 1);

          &:hover {
            background-color: #d2ebdc;
          }
        }
      }
    }
  }
}
</style>