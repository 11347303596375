<template>
  <div class="main-wrapper">
    <div class="function-box">
      <div class="search-box">
        <input class="search-input" :class="{ focus: searchFocus }" type="text" v-model="search" placeholder="搜索内容名称" @keydown.enter="searchList" @focus="searchFocus = true" @blur="searchFocus = false">
        <i class="iconfont icon-search3"></i>
      </div>

      <div class="btns">
        <div class="item">
          <i class="iconfont icon-qingkong"></i>
          <input class="clear-btn" :class="{ disabled: setGoodsDisabled }" type="button" value="清空当前表" @click="clearCurList" v-if="sort.id == 1">
          <input class="clear-btn" :class="{ disabled: setBillDisabled }" type="button" value="清空当前表" @click="clearCurList" v-if="sort.id == 2">
        </div>

        <div class="item">
          <i class="iconfont icon-15qingkong-1"></i>
          <input class="clear-btn" :class="{ disabled: setAllDisabled }" type="button" value="清空回收站" @click="clearAllList">
        </div>

        <el-select class="sort-box" v-model="sort.id" filterable clearable placeholder="商品列表" @change="showListById" @clear="clearSort">
          <el-option
            v-for="item in sortList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 商品列表 -->
    <div class="list-box" v-if="sort.id == 1">
      <div class="list" v-for="(good, index) in goodsList" :key="index">
        <div class="item">
          <div class="title">Name</div>
          <div class="title-value">{{ good.name }}</div>
        </div>
        
        <div class="item">
          <div class="title">Desc</div>
          <div class="title-value">{{ good.desc }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Price</div>
          <div class="title-value">{{ good.price }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Speci</div>
          <div class="title-value">{{ good.speci }}</div>
        </div>

        <div class="item limit06">
          <div class="title">Cost</div>
          <div class="title-value">{{ good.cost }}</div>
        </div>

        <div class="item limit075">
          <div class="title">From</div>
          <div class="title-value">{{ good.from?.label }}</div>
        </div>

        <div class="item">
          <div class="title">Remark</div>
          <div class="title-value">{{ good.remark }}</div>
        </div>

        <div class="item">
          <div class="title">Actions</div>
          <div class="title-value">
            <input type="button" value="还原" @click="restoreItem(good.id)">
            <input type="button" value="彻底删除" @click="forceDeleteItem(good.id)">
          </div>
        </div>
      </div>

      <div class="noData" v-if="showGoodsNoData"><i class="iconfont icon-a-UploadFailed"></i></div>

      <div class="pagination-filters">
        <div class="show-list">
          <el-dropdown trigger="click" type="primary">
            <span class="el-dropdown-link">
              Show result<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in pageDropdownList" :key="item" @click.native="goodsSizeChange(item)">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
        <el-pagination
          @size-change="goodsSizeChange"
          @current-change="goodsCurrentChange"
          :current-page.sync="goodsCurrentPage"
          :page-size="goodsPageSize"
          layout="prev, pager, next, jumper"
          :total="goodsTotal"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
    </div>

    <!-- 账单列表 -->
    <div class="list-box" v-if="sort.id == 2">
      <el-table :data="billList" ref="billlist">
        <el-table-column prop="date" label="Date" width="108"></el-table-column>
        <el-table-column prop="label" label="Label" width="250"></el-table-column>
        <el-table-column prop="amount" label="Amount" width="100"></el-table-column>
        <el-table-column prop="tool" label="Tool" width="130"></el-table-column>
        <el-table-column prop="target.label" label="Target" width="140"></el-table-column>
        <el-table-column prop="remark" label="Remark"></el-table-column>
        <el-table-column label="Action" width="200">
          <template slot-scope="scope">
            <el-button size="small" type="success" icon="el-icon-refresh" @click="restoreItem(scope.row.id)">还原</el-button>
            <el-button size="small" type="info" icon="el-icon-delete" plain @click="forceDeleteItem(scope.row.id)">彻底删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-filters">
        <div class="show-list">
          <el-dropdown trigger="click" type="primary">
            <span class="el-dropdown-link">
              Show result<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in pageDropdownList" :key="item" @click.native="billSizeChange(item)">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
        <el-pagination
          @size-change="billSizeChange"
          @current-change="billCurrentChange"
          :current-page.sync="billCurrentPage"
          :page-size="billPageSize"
          layout="prev, pager, next, jumper"
          :total="billTotal"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';

export default {
  data (){
    return {
      search: '',
      sort: {
        id: 1, // 默认为商品列表
        label: '商品列表'
      },
      sortList: [{ id: 1, label: '商品列表' }, { id: 2, label: '账单列表' }],
      goodsList: [], // 商品假删除列表
      billList: [], // 账单假删除列表
      showGoodsNoData: false,
      setGoodsDisabled: false, // 禁用清空商品列表按钮
      setBillDisabled: false, // 禁用清空账单列表按钮
      setAllDisabled: false, // 禁用清空回收站按钮
      searchFocus: false, // 显示边框

      // 商品分页
      goodsCurrentPage: 1,
      goodsPageSize: 30,
      goodsTotal: 0,
      // 账单分页
      billCurrentPage: 1,
      billPageSize: 30,
      billTotal: 0,
      pageDropdownList: [5, 10, 15, 20, 30, 50, 100],
    }
  },

  methods: {
    init () {
      this.getRecycleList();
    },

    // 自定义提示
    _message (type, message, duration=3000, closeCallback=() => {}) {
      this.$message({
        type,
        message,
        duration,
        onClose: closeCallback && closeCallback()
      })
    },

    // 获取假删除列表,sort_id 为 1 时,为商品列表,为 2 时,为账单列表
    getSortList (sort_id, params) {
      let url = '';
      switch(sort_id) {
        case 1:
          url = '/goods';
          break;
        case 2:
          url = '/bill';
          break;
      }

      let _params = {};

      if (this.sort.id == 1) {
        _params = {
          paranoid: false,
          page: this.goodsCurrentPage,
          countPerPage: this.goodsPageSize
        }
      }

      if (this.sort.id == 2) {
        _params = {
          paranoid: false,
          page: this.billCurrentPage,
          countPerPage: this.billPageSize
        }
      }

      if (params) {
        Object.assign(_params, params);
      }

      return new Promise(resolve => {
        request.get(url, { params: _params })
        .then (res => {
          if (res.data.message) {
            if (res.data?.data?.rows?.length > 0) {
              let result = res.data.data;
              let data = result.rows;
              let count = result.count;
  
              if (this.sort.id == 1) {
                this.goodsTotal = count;
              }
              
              if (this.sort.id == 2) {
                this.billTotal = count;
              }
    
              resolve(data);
            }
            else {
              if (sort_id == 1) {
                this.showGoodsNoData = true;
                this.setGoodsDisabled = true;
              }

              if (sort_id == 2) {
                this.setBillDisabled = true;
              }
              
              resolve([]);
            }
          }
        })
      })
    },

    // 获取商品列表
    async getRecycleList () {
      if (this.sort.id == 1) {
        let goodsList = await this.getSortList(1);
        this.goodsList = goodsList;
        // 手动调整列表类型,因为初始化时 sort.id 是不变的
        this.$set(this.sort, 'id', 2);
      }

      if (this.sort.id == 2) {
        let billList = await this.getSortList(2);
        this.billList = billList;

        this.$set(this.sort, 'id', 1);
      }

      if (this.goodsList.length == 0 && this.billList.length == 0) {
        this.setAllDisabled = true;
      }
    },

    // 从 sort.id 获取相应的 label
    getText (id) {
      let cur = this.sortList.filter(i => i.id == id);
      let label = cur.length > 0 ? cur[0].label : '';

      return label;
    },

    // 选择列表选项
    showListById () {
      let label = this.getText(this.sort.id);
      this.$set(this.sort, 'label', label);
    },

    // 清除列表选项
    clearSort () {
      // 恢复默认商品列表
      let label = this.getText(1);
      this.$set(this.sort, 'id', 1);
      this.$set(this.sort, 'label', label);
    },

    // 还原项
    // this.sort.id 表示商品或账单,1为商品,2为账单
    restoreItem (id) {
      let url = '';
      switch(this.sort.id) {
        case 1:
          url = 'goods';
          break;
        case 2:
          url = 'bill';
          break;
      }

      this.$confirm('确定要还原吗？', '还原', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
      .then (() => {
        request({
          url: `/${ url }/${ id }`,
          method: 'put',
          data: { deletedAt: null }
        })
        .then (async res => {
          if (res.data.message) {
            this._message('success', '已还原该条信息！')

            if (this.sort.id == 1) {
              if (this.search) {
                this.search = this.search.trim();
                let data = await this.getSortList(1, { name: this.search });
                this.goodsList = data;
              }
              else {
                let data = await this.getSortList(1);
                this.goodsList = data;
              }
            }

            if (this.sort.id == 2) {
              if (this.search) {
                this.search = this.search.trim();
                let data = await this.getSortList(2, { name: this.search });
                this.billList = data;
              }
              else {
                let data = await this.getSortList(2);
                this.billList = data;
              }
            }

            if (this.goodsList.length == 0 && this.billList.length == 0) {
              this.setAllDisabled = true;
            }
          }
          else {
            this._message('info', '还原失败！');
          }
        })
      })
      .catch (() => {});
    },

    // 彻底删除项
    // this.sort.id 表示商品或账单,1为商品,2为账单
    forceDeleteItem (id) {
      let url = '';
      switch(this.sort.id) {
        case 1:
          url = 'goods';
          break;
        case 2:
          url = 'bill';
          break;
      }

      let query = { force: true };
      if (this.search) {
        this.search = this.search.trim();
        query.search = this.search;
      }

      this.$confirm('将彻底删除该条信息,继续吗？', '彻底删除', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
      .then (() => {
        // delete 要获取 force 属性值必须使用这种请求方式(不明原因)
        request({
          url: `/${ url }/${ id }`,
          method: 'delete',
          data: query
        })
        .then (async res => {
          if (res.data.message) {
            this._message('success', '删除成功！');

            // 商品列表
            if (this.sort.id == 1) {
              if (this.search) {
                this.search = this.search.trim();
                let data = await this.getSortList(1, { name: this.search });
                this.goodsList = data;
              }
              else {
                let data = await this.getSortList(1);
                this.goodsList = data;
              }
              
              if (this.goodsList.length == 0) {
                this.showGoodsNoData = true;
                this.setGoodsDisabled = true;
              }
            }

            // 账单列表
            if (this.sort.id == 2) {
              if (this.search) {
                this.search = this.search.trim();
                let data = await this.getSortList(2, { label: this.search });
                this.goodsList = data;
              }
              else {
                let data = await this.getSortList(2);
                this.billList = data;
              }
              

              if (this.billList.length == 0) {
                this.setBillDisabled = true;
              }
            }

            if (this.goodsList.length == 0 && this.billList.length == 0) {
              this.setAllDisabled = true;
            }
          }
          else {
            this._message('info', '删除失败！');
          }
        })
      })
      .catch (() => {});
    },

    // 清空当前表
    clearCurList () {
      this.$confirm('将永久删除列表项，确定继续吗？', '清空', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
      .then (async () => {
        // 如果当前是商品列表
        if (this.sort.id == 1) {
          // 查找当前所有删除项的 id
          let res = await this.getSortList(1);
          let promises = res.map(i => {
            request({
              url: `/goods/${ i.id }`,
              method: 'delete',
              data: { force: true }
            })
          })
  
          Promise.all(promises)
          .then (() => {
            this.goodsList = []; // 清空列表
            this.showGoodsNoData = true;
            this.setGoodsDisabled = true;

            if (this.billList.length == 0) {
              this.setAllDisabled = true;
            }
          });
        }
        
        // 如果当前是账单列表
        if (this.sort.id == 2) {
          // 查找当前所有删除项的 id
          let res = await this.getSortList(2);
          let promises = res.map(i => {
            request({
              url: `/bill/${ i.id }`,
              method: 'delete',
              data: { force: true }
            })
          })
  
          Promise.all(promises)
          .then (() => {
            this.billList = []; // 清空列表
            this.setBillDisabled = true;

            if (this.goodsList.length == 0) {
              this.setAllDisabled = true;
            }
          });
        }

        this.search = ''; // 清除搜索内容
      })
      .catch (() => {})
    },

    // 清空回收站
    clearAllList () {
      this.$confirm('将永久删除回收站所有内容，确定继续吗？', '清空回收站', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
      .then (() => {
        let goodsPromise;
        let billPromise;
        
        if (this.goodsList?.length > 0) {
          goodsPromise = this.goodsList.map(i => {
            request({
              url: `/goods/${ i.id }`,
              method: 'delete',
              data: { force: true }
            })
          })
        }
  
        if (this.billList?.length > 0) {
          billPromise = this.billList.map(i => {
            request({
              url: `/bill/${ i.id }`,
              method: 'delete',
              data: { force: true }
            })
          })
        }

        if (goodsPromise && !billPromise) {
          Promise.all(goodsPromise);
        }

        if (!goodsPromise && billPromise) {
          Promise.all(billPromise);
        }

        if (goodsPromise && billPromise) {
          Promise.all([goodsPromise, billPromise]);
        }
  
        this.search = ''; // 清除搜索内容
      
        this.goodsList = [];
        this.showGoodsNoData = true;
        this.billList = [];

        this.setGoodsDisabled = true;
        this.setBillDisabled = true;
        this.setAllDisabled = true;
      })
      .catch (() => {})
    },
    
    // 搜索
    async searchList () {
      this.search = this.search.trim();

      if (!this.search) return;

      // 如果当前是商品列表
      if (this.sort.id == 1) {
        let data = await this.getSortList(1, { name: this.search });
        this.goodsList = data;

        if (this.goodsList.length == 0) {
          this.showGoodsNoData = true;
        }
      }

      // 如果当前是账单列表
      if (this.sort.id == 2) {
        let data = await this.getSortList(2, { name: this.search });
        this.billList = data;
      }
   },

    // 商品列表 - 获取每页条数
    async goodsSizeChange(val) {
      this.goodsCurrentPage = 1; // 页码恢复到第一页
      this.goodsPageSize = val; // 更改每页条数
      
      let params = {};
      if (this.search) {
        this.search = this.search.trim();
        params = { name: this.search };
      }

      let data = await this.getSortList(1, params);
      this.goodsList = data;
    },

    // 商品列表 - 获取当前所在页码
    async goodsCurrentChange(val) {
      this.goodsCurrentPage = this.goodsCurrentPage == 1 ? this.goodsCurrentPage : val;
      let data = await this.getSortList(1);
      this.goodsList = data;
    },

    // 账单列表 - 获取每页条数
    async billSizeChange(val) {
      this.billCurrentPage = 1; // 页码恢复到第一页
      this.billPageSize = val; // 更改每页条数
      
      let params = {};
      if (this.search) {
        this.search = this.search.trim();
        params = { name: this.search };
      }

      let data = await this.getSortList(2, params);
      this.billList = data;
    },

    // 账单列表 - 获取当前所在页码
    async billCurrentChange(val) {
      this.billCurrentPage = this.billCurrentPage == 1 ? this.billCurrentPage : val;
      let data = await this.getSortList(2);
      this.billList = data;
    },
  },

  beforeUpdate () {
    this.$nextTick(() => {
      // 解决 el-table 闪烁的问题
      if (this.sort.id == 2 && this.$refs.length > 0) {
        this.$refs?.billlist.doLayout();
      }
    })
  },

  mounted () {
    this.init();
  },

  watch: {
    async search (newval) {
      if (newval.length == 0) {
        // this.getRecycleList();
        // 如果当前是商品列表
        if (this.sort.id == 1) {
          let data = await this.getSortList(1, { name: this.search });
          this.goodsList = data;

          if (this.goodsList.length == 0) {
            this.showGoodsNoData = true;
          }
        }

        // 如果当前是账单列表
        if (this.sort.id == 2) {
          let data = await this.getSortList(2, { name: this.search });
          this.billList = data;
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.noData i {
  margin: 5rem 0;
}

.function-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;

  .search-box {
    position: relative;
    flex: 1;

    .search-input {
      background-color: #f1f2f5;
      border-radius: 0.8rem;
      padding: 1.5rem;
      text-indent: 3.5rem;
      width: 60%;
      font-size: 1.4rem;
      border: 1px solid transparent;
      
      &.focus {
        border-color: #22C55E;
      }
    }

    i {
      position: absolute;
      top: 1.06rem;
      left: 1.5rem;
      z-index: 2;
      font-size: 2.5rem;
    }
  }

  .btns {
    display: flex;
    align-items: center;

    .item {
      position: relative;

      i {
        position: absolute;
        left: 1rem;
        top: 1.12rem;
        color: white;
      }

      .clear-btn {
        background-color: #22C55E;
        border-radius: $borderRadius08;
        color: white;
        padding: 1rem 2rem 1rem 3rem;
        margin-right: 1rem;
        cursor: pointer;
        transition: .3s ease;
  
        &:hover {
          background-color: #30DC2D;
          transition: .3s ease;
        }
      }
    }
    
    .sort-box {
      width: 15rem;
    }
  }
}

.list-box {
  .list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 1rem;
    transition: .25s ease;
  
    &:hover {
      background-color: #F5F7FA;
      transition: .25s ease;
    }
  
    .item {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.limit06 {
        flex: .6;
      }

      &.limit075 {
        flex: .75;
      }
  
      &.btns {
        flex: unset;
      }
      
      .title {
        margin-bottom: 1rem;
        color: darkgrey;
        font-size: 1.35rem;
        margin-bottom: auto;
      }
  
      .title-value {
        font-size: 1.5rem;
        margin-bottom: auto;

        input {
          background-color: antiquewhite;
          padding: 0.4rem 1rem;
          border-radius: 0.6rem;
          cursor: pointer;
          color: rgba(0,0,0,.8);
          transition: .3s ease;

          &:last-child {
            margin-left: 1rem;
          }

          &:hover {
            background-color: #e9d4b7;
            transition: .3s ease;
          }
        }
      }
  
      .action-more {
        cursor: pointer;
        padding: 1rem;
      }
  
      .item-action {
        position: absolute;
        top: 3rem;
        right: 0;
        z-index: 31;
        border-radius: .8rem;
        filter: drop-shadow(rgba(0, 0, 0, 0.08) 12px 12px 40px);
        overflow: hidden;
  
        & > li {
          height: 100%;
          display: flex;
          border-bottom: 1px solid #EDF2F7;
  
          .text {
            cursor: pointer;
            padding: 2rem 6rem;
            width: 100%;
            text-align: center;
            background-color: white;
            transition: .3s ease;
  
            &:hover {
              color: white;
              background-color: #22C55E;
              transition: .3s ease;
            }
          }
  
          &:last-child {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }
}
</style>
