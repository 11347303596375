<template>
  <div class="backdrop transparent" @click="showIt"></div>
</template>

<script>
export default {
  props: ['transparent'],
  methods: {
    showIt () {
      this.$emit('getShowInfo', false);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
</style>
