import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

// 引入 animate.css（这种方式打包后报错）
// import animated from 'animate.css'
// Vue.use(animated)
// 引入 animate.css（建议使用）
import 'animate.css';

// 引入 element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

// 引入 echarts
// import echarts from 'echarts'; // 这种引入方式引入不了,会报错
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts; // 必须在 vue 中重新定义方法才有效

// 引入自定义css
import './assets/css/iconfont.scss';
import './assets/font/font.scss';
import './assets/css/common.scss';

Vue.config.productionTip = false

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
import VueRouter from 'vue-router';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}

/* // 自定义服务器接口
let baseUrl = "http://test.goaread.com";
Vue.prototype.baseUrl = baseUrl;
axios.defaults.baseURL = baseUrl;
Vue.mixin({
  methods: {
    getUrl (url) {
      return baseUrl + url;
    }
  }
}) */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
