<template>
  <div class="main-wrapper">
    <paylist :targetID="6" />
  </div>
</template>

<script>
import paylist from '../components/Paylist.vue';

export default {
  components: {
    paylist
  }
}
</script>

<style lang="scss" scoped>
</style>
