<template>
  <div>
    <div class="animate__animated animate__fadeIn slower count-box">
      <div class="total-count">
        <div class="title">
          <div class="text">Expenditure Situation</div>
          <el-date-picker v-model="curDate" type="month" placeholder="选择月" @change="getOrClearMonth"></el-date-picker>
        </div>

        <!-- 总支出 -->
        <div class="calc-result">
          <div class="text-box">
            <span class="sub">Total expenditure for this month</span>
            <span class="text">￥{{ totalCount.toFixed(2) }} <span class="animate__animated animate__fadeIn current" v-if="isCurrentMonth">（当前月）</span></span>
          </div>
          <img src="../assets/img/data-line.png" alt="">
        </div>

        <!-- 各部分占比环形图 -->
        <div class="pattern-box">
          <div class="title">Proportion of each part</div>
          <div class="total-pattern" style="width: 30rem; height: 30rem;"></div>
        </div>
      </div>

      <!-- 各部分占比 -->
      <div class="paylist-box">
        <div class="pay-item" v-for="(item, index) in series" :key="index">
          <div class="amount">
            <span class="num">￥{{ item.count.toFixed(2) }}</span>
            <span class="from" v-if="item.from">{{ item.from }}</span>
            <span class="no-source" v-else>暂无数据源</span>
          </div>

          <el-progress class="animate__animated" :class="progressClass(index)" type="circle" :percentage="item.percent" :width="80" :stroke-width="10" ></el-progress>
        </div>
        
        <i></i>
        <i></i>
      </div>
    </div>

    <!-- 账单列表 -->
    <div class="bill-container">
      <div class="container-box">
        <div class="search-box">
          <div class="search">
            <i class="iconfont icon-search3"></i>
            <input type="text" class="search-input" :class="{ focus: searchFocus }" v-model="search" placeholder="请输入名称内容..." :disabled="searchDisabled" @focus="searchFocus = true" @blur="searchFocus = false" @keyup.enter="doSearch">
          </div>
        </div>

        <div class="functions">
          <div class="date-picker">
            <el-date-picker
              v-model="datetime"
              type="datetimerange"
              :picker-options="pickerOptions"
              start-placeholder="开始日期"
              range-separator="至"
              end-placeholder="结束日期"
              align="right"
              :disabled="disabled"
              @change="getDateResult">
            </el-date-picker>
          </div>

          <el-select class="from-box" v-model="from" filterable clearable placeholder="From" :disabled="fromDisabled" @change="showListByFrom" @clear="clearFrom">
            <el-option
              v-for="item in fromList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
              :disabled="disabled"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="bill-list">
        <el-table :data="payList" ref="paylist">
          <el-table-column prop="date" label="Date" width="150"></el-table-column>
          <el-table-column prop="label" label="Label" width="300"></el-table-column>
          <el-table-column prop="amount" label="Amount" width="120"></el-table-column>
          <el-table-column prop="tool" label="Tool" width="140"></el-table-column>
          <el-table-column prop="target.label" label="Target" width="140"></el-table-column>
          <el-table-column prop="remark" label="Remark"></el-table-column>
        </el-table>

        <div class="pagination-filters">
          <div class="show-list">
            <el-dropdown trigger="click" type="primary">
              <span class="el-dropdown-link">
                Show result<i class="el-icon-arrow-down el-icon--right"></i>
              </span>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in pageDropdownList" :key="item" @click.native="handleSizeChange(item)">{{ item }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
            :hide-on-single-page="true">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import request from '../utils/request';

export default {
  data () {
    return {
      timer: null,
      curDate: '', // 选择月
      isCurrentMonth: false, // 是否最新当前月
      totalCount: 0, // 总支出
      queryBillList: [], // 账单条件列表
      series: [], // 分类
      // 账单列表相关
      search: '',
      searchFocus: false,
      disabled: false,
      datetime: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      from: '',
      fromDisabled: false,
      payList: [],
      currentPage: 1,
      pageSize: 30,
      total: 0,
      pageDropdownList: [5, 10, 15, 20, 30, 50, 100], // 每页显示条数列表
      searchDisabled: false,
    }
  },

  computed: {
    ...mapState(['fromList']),
  },

  methods: {
    init () {
      this.getCurDate(); // 默认当前月
      this.getTotalPay({ date: this.curDate });
      this.getAllBillList();
    },
    
    // 提示封装
    messageAlert (type, message) {
      this.$message({
        type,
        message
      })
    },

    // 获取当前年月
    getCurDate () {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;

      this.curDate = m >= 10 ? `${ y }-${ m }` : `${ y }-0${ m }`;
    },

    // 获取支出列表
    getTotalPayList (query=null) {
      return new Promise((resolve) => {
        return request.get(`/bill`, {
          params: query
        })
        .then (res => {
          if (res.data.message) {
            if (res.data?.data?.rows?.length > 0) {
              let data = res.data.data.rows;
              resolve(data);
            }
            else {
              resolve(false);
            }
          }
        })
      })
    },

    // 配置环形图
    setCirclePattern () {
      let totalCalc = this.$echarts.init(document.querySelector('.total-pattern'));

      let data = [];
      this.series.map(i => {
        data.unshift({ value: Math.round(i.count * 100) / 100, name: i.from })
      })
      
      //粘贴官网配置好的选项
      let option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ' 50%',
            /* data: [
              { value: this.series[0].count, name: '味予餐饮（公司）' },
              { value: 735, name: '食为奴' },
              { value: 580, name: '美菜' },
              { value: 484, name: '美团' },
              { value: 1300, name: '淘宝' },
              { value: 450, name: '微信' },
              { value: 562, name: '其他' },
            ], */
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
     
      //给初始化好的容器添加配置项
      option && totalCalc.setOption(option);
    },

    // 计算支出
    async getTotalPay (query=null) {
      this.queryBillList = []; // 先清空
      let queryPayList = await this.getTotalPayList(query);

      if (queryPayList?.length > 0) {
        // 先清空归零
        this.totalCount = 0;

        // 再赋值
        this.queryBillList = queryPayList;
        // 计算本月总支出
        queryPayList.map(i => {
          this.totalCount += Number(i.amount);
        })
      }

      // 计算出总支出后再计算各部分和占比
      this.getSeriesData();
    },

    // 获取各分类支出和占比
    getSeriesData () {
      let fromLen = this.fromList.length;

      // 当前月有数据
      if (this.queryBillList?.length > 0) {
        for (let i = 0; i < fromLen; i++) {
          let id = this.fromList[i].id;
          let curList = this.queryBillList.filter(j => j.target.id == id);
  
          // 根据月份计算当月总支出
          let sum = 0;
          curList.map(k => sum += k.amount);
  
          let percent = Number((sum / this.totalCount * 100).toFixed(1));
          let from = this.fromList[i].label;
          this.$set(this.series, i, { id: i+1, count: sum, percent, from });
        }
      }
      // 当前月没有数据
      else {
        this.totalCount = 0; // 总支出归零

        let sum = 0;
        let percent = 0;
        
        for (let i = 0; i < fromLen; i++) {
          this.$set(this.series, i, { id: i+1, count: sum, percent });
        }
      }

      // 分配图表
      this.setCirclePattern();
    },

    // 选择月
    async getOrClearMonth () {
      // 如果清除月份,则回到当前月
      if (!this.curDate) {
        this.getCurDate(); // 获取当前月
        this.getTotalPay({ date: this.curDate });
        this.isCurrentMonth = true; // 显示当前月标记
        return;
      }
      
      // 如果是选择月份,则获取当月数据
      this.isCurrentMonth = false; // 清除当前月标记
      let y = new Date(this.curDate).getFullYear();
      let m = new Date(this.curDate).getMonth() + 1;
      let date = m >= 10 ? `${ y }-${ m }` : `${ y }-0${ m }`;
      let params = { date }

      this.getTotalPay(params);
    },

    // progress 动画效果及过渡时间绑定样式的处理
    progressClass (index) {
      let c = [];

      // 偶数项
      if (index % 2 == 0) {
        c.push('animate__backInDown');
      }

      // 奇数项
      if (index % 2 > 0) {
        c.push('animate__backInLeft');
      }
  
      // duration 过渡必须在效果之后实现,否则无效
      c.push(`speed${ 10 - index }`);

      return c;
    },

    /**
     * 账单列表相关
     */
    // 获取日期时间格式
    getDatetime (time) {
      let curdate = new Date(time);
      let y = curdate.getFullYear();
      let m = curdate.getMonth() + 1;
      let d = curdate.getDate();
      let hh = curdate.getHours();
      let mm = curdate.getMinutes();
      let ss = curdate.getSeconds();

      m = m > 10 ? m : '0' + m;
      d = d > 10 ? d : '0' + d;
      hh = hh > 10 ? hh : '0' + hh;
      mm = mm > 10 ? mm : '0' + mm;
      ss = ss > 10 ? ss : '0' + ss;

      let res = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss;
      return res;
    },
    
    // 获取账单总列表
    getAllBillList (params) {
      let _params = { page: this.currentPage, countPerPage: this.pageSize };
      if (params) {
        Object.assign(_params, params);
      }

      request.get(`/bill`, { params: _params })
      .then (res => {
        if (res.data.message) {
          if (res.data?.data?.rows?.length > 0) {
            let result = res.data.data;
            let data = result?.rows;
            let count = result?.count;
  
            this.payList = []; // 清空列表原数据
            data.map(i => {
              i.amount = i.amount ? Number(i.amount).toFixed(2) : '';
            })
            this.payList = data;
            this.total = count;
            this.searchDisabled = false;
            this.disabled = false;
            this.fromDisabled = false;
          }
          else {
            this.payList = [];
            this.total = 0;
          }
        }
      })
    },

    // 搜索
    doSearch () {
      this.search = this.search.trim();

      if (!this.search && this.datetime.length == 0 && !this.from) return;

      this.currentPage = 1;

      let params = {};
      // 如果有搜索内容
      if (this.search) {
        // 有时间段
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          // 有渠道
          if (this.from) {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              name: this.search,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search,
            }
          }
        }
      }
      // 没有搜索内容
      else {
        // 有时间段
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          // 有渠道
          if (this.from) {
            params = {
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              target: this.from
            }
          }
        }
      }

      this.getAllBillList(params);
    },
  
    // 选择时间段
    // 获取列表中大于选择的初始日期,小于选择的终止日期
    getDateResult () {
      this.currentPage = 1;
      this.search = this.search ? this.search.trim() : '';

      let preDatetime = this.getDatetime(this.datetime[0]);
      let nextDatetime = this.getDatetime(this.datetime[1]);

      let params = {};
      if (this.search) {
        if (this.from) {
          params = {
            name: this.search,
            target: this.from,
            preDatetime,
            nextDatetime,
          }
        }
        else {
          params = {
            name: this.search,
            preDatetime,
            nextDatetime,
          }
        }
      }
      else {
        if (this.from) {
          params = {
            preDatetime,
            nextDatetime,
            target: this.from
          }
        }
        else {
          params = {
            preDatetime,
            nextDatetime,
          }
        }
      }

      this.getAllBillList(params);
    },

    // 清除时间段
    clearDateTime () {
      this.currentPage = 1;

      let name = this.search ? this.search.trim() : '';
      let params = {};

      if (name) {
        if (this.from) {
          params = {
            name,
            target: this.from
          }
        }
        else {
          params = { name }
        }
      }
      else {
        if (this.from) {
          params = {
            target: this.from
          }
        }
      }

      this.getAllBillList(params);
    },

    // 选择渠道
    showListByFrom () {
      this.currentPage = 1;
      this.search = this.search.trim();

      let params = {};
      if (this.search) {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          params = {
            name: this.search,
            preDatetime,
            nextDatetime,
            target: this.from
          }
        }
        else {
          params = {
            name: this.search,
            target: this.from
          }
        }
      }
      else {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          params = {
            preDatetime,
            nextDatetime,
            target: this.from
          }
        }
        else {
          params = {
            target: this.from
          }
        }
      }
      
      this.getAllBillList(params);
    },

    // 清空渠道
    clearFrom () {
      this.currentPage = 1;
      this.search = this.search ? this.search.trim() : '';

      // 如果存在搜索内容
      let param = {};
      if (this.search) {
        if (this.datetime.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          params = {
            name: this.search,
            preDatetime,
            nextDatetime,
          }
        }
        else {
          params = {
            name: this.search
          }
        }
      }
      else {
        if (this.datetime.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          params = {
            preDatetime,
            nextDatetime,
          }
        }
      }

      this.getAllBillList(params);
    },

    // 改变 pageSize
    handleSizeChange (size) {
      this.currentPage = 1;
      this.pageSize = size;

      // 根据条件加载数据
      let params = {};
      this.search = this.search ? this.search.trim() : '';

      if (this.search) {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          if (this.from) {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              name: this.search,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search
            }
          }
        }
      }
      else {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          if (this.from) {
            params = {
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              target: this.from
            }
          }
        }
      }

      this.getAllBillList(params);
    },

    // 翻页
    handleCurrentChange (val) {
      this.currentPage = this.currentPage == 1 ? this.currentPage : val;

      // 根据条件加载数据
      let params = {};
      this.search = this.search ? this.search.trim() : '';

      if (this.search) {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          if (this.from) {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search,
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              name: this.search,
              target: this.from
            }
          }
          else {
            params = {
              name: this.search
            }
          }
        }
      }
      else {
        if (this.datetime?.length > 0) {
          let preDatetime = this.getDatetime(this.datetime[0]);
          let nextDatetime = this.getDatetime(this.datetime[1]);

          if (this.from) {
            params = {
              preDatetime,
              nextDatetime,
              target: this.from
            }
          }
          else {
            params = {
              preDatetime,
              nextDatetime,
            }
          }
        }
        else {
          if (this.from) {
            params = {
              target: this.from
            }
          }
        }
      }

      this.getAllBillList(params);
    },
  },

  mounted () {
    this.init();
  },
  
  watch: {
    datetime(n, o) {
      this.datetime = !n ? [] : n;
      if (n.length == 0 || n == null) {
        this.clearDateTime();
      }
    },
    search(newval) {
      if (newval.length == 0) {
        this.getAllBillList();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

.count-box {
  display: flex;
}

.total-count {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: $borderRadius08;
  margin-right: 3rem;
  flex: .8;
  overflow: hidden;

  & > .title {
    padding: 2rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    margin-bottom: 2rem;

    .text {
      font-weight: bold;
      font-size: 2rem;
    }

    .el-date-editor.el-input {
      width: 15rem;
    }
  }

  .calc-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    margin-bottom: 2rem;
    
    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .sub {
        font-size: 1.2rem;
        color: gray;
        margin-bottom: 0.5rem;
      }
  
      .text {
        font-size: 2.8rem;
        font-weight: bold;
        color: #1a202c;

        .current {
          color: red;
          font-size: 1.2rem;
        }
      }
    }

    img {
      width: 10rem;
      height: 6.8rem;
      display: block;
    }
  }

  .pattern-box {
    border-top: 1px solid #E2E8F0;

    .title {
      padding: 2rem 0 2rem 2rem;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
    }

    .total-pattern {
      padding-top: 0;
      margin: auto;
    }
  }
}

.paylist-box {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pay-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 2rem;
    margin-bottom: 3rem;
    border-radius: $borderRadius08;
    width: calc(50% - 5rem);
    overflow: hidden;

    .amount {
      display: flex;
      flex-direction: column;

      .num {
        font-size: 2.2rem;
        font-weight: bold;
        color: #1a202c;
      }

      .from {
        color: rgba(0,0,0,.6);
        margin-left: 0.5rem;
      }

      .no-source {
        color: #999;
      }
    }
  }

  i {
    flex: 1;
  }
}

.total-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    flex: 1;
    margin-top: 2.5rem;
    margin-right: 2.5rem;
    width: 25%;

    &:last-child {
      margin-right: 0;
    }

    .total-card {
      padding: 2rem;
      border-radius: $borderRadius08;
      background-color: white;
      display: flex;
      flex-direction: column;

      .header {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 1.6rem;

        img {
          margin-right: 1rem;
        }
      }

      .number-box {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .number {
          font-size: 2.8rem;
          font-weight: bold;
        }

        img {
          width: 10rem;
          height: 6.8rem;
          display: block;
        }
      }
    }
  }

  i {
    width: 25%;
  }
}

.bill-container {
  background-color: white;
  border-radius: $borderRadius08;
  padding: 0.5rem 2rem;
  margin: 3rem 0 2rem;
  
  .container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0 0;

    .search-box {
      flex: 1;
      position: relative;
      margin-right: 3rem;

      .search {
        display: flex;

        i {
          font-size: 2.8rem;
          position: absolute;
          top: 1.52rem;
          left: 2rem;
        }

        .search-input {
          flex: 1;
          border: 1px solid transparent;
          border-radius: $borderRadius08;
          padding: 2rem 3rem;
          text-indent: 3rem;
          font-size: 1.4rem;
          background-color: #f1f2f5;

          &.focus {
            border-color: #22C55E;
          }
        }
      }
    }

    .functions {
      flex: 1;
      display: flex;
      align-items: center;

      /* .date-picker {
        margin: 30px 0;
      } */

      .from-box {
        min-width: 18.5rem;
        flex: 1;
        margin-left: 1rem;
      }
    }
  }

  .bill-list {
    margin: 2rem 0;
  }
}
</style>