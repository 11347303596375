<template>
  <header>
    <div class="title">
      <h1 class="text">财务统计系统</h1>
      <span class="sub-text">Financial Statistics System</span>
    </div>

    <div class="header-container">
      <div class="item" @click="$router.push('/iedetail')">
        <i class="iconfont icon-shouzhicun1"></i>
      </div>

      <div class="item">
        <img @click="showProfile = true" class="thumbnail" :src="avatar" alt="">

        <div class="profile-wrapper">
          <backdrop :transparent=true @getShowInfo="showInfo" v-if="showProfile" />

          <ul class="profile-list" v-if="showProfile">
            <li @click="setPage"><i class="iconfont icon-Setting"></i>设置</li>
            <!-- <li @click="getMessage"><i class="iconfont icon-Setting"></i>获取数据（测试带 token）</li> -->
            <li @click="logout"><i class="iconfont icon-logout"></i>退出</li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import axios from 'axios';
import backdrop from '@/components/Backdrop';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      showProfile: false,
      avatar: '',
    }
  },

  computed: {
    ...mapState(['ab_url', 'user']),
  },

  methods: {
    init () {
      this.avatar = this.ab_url + this.user.avatar;
    },

    showInfo (value) {
      this.showProfile = value;
    },

    setPage () {
      this.showProfile = false;
      this.$router.push('/user');
    },

    // for test
    /* getMessage () {
      let token = localStorage.getItem('token');
      axios.get('http://127.0.0.1:7001/jwtmessage', { headers: { token } })
      .then (res => {
        console.log(res.data)
      })
    }, */
    
    logout () {
      this.$confirm('确定要退出吗？', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then (res => {
        this.showProfile = false;

        localStorage.setItem('token', '');
        localStorage.setItem('user', '');
  
        this.$message({
          type: 'info',
          message: '已退出登录',
          duration: 1000,
          onClose: () => {
            this.$router.push('/login');
          }
        })
      })
      .catch (() => {
        this.showProfile = false;
      })
    },
  },

  mounted () {
    this.init();
  },

  components: {
    backdrop,
  },

  watch: {
    user (newval) {
      this.avatar = this.ab_url + newval.avatar;
    },
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 30;
  width: calc(100% - 30.8rem);

  .title {
    height: 10.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4rem;

    .text {
      color: rgba(26, 32, 44, 1);
      font-size: 2.2rem;
      margin: 0;
    }

    .sub-text {
      color: rgba(113, 128, 150, 1);
      font-size: 1.4rem;
      margin-top: .5rem;
    }
  }

  .header-container {
    margin-left: auto;
    margin-right: 4rem;
    display: flex;
    align-items: center;

    .item {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
      background: rgba(254, 99, 78, 0.1);
      width: 5rem;
      height: 5rem;
      border-radius: 1.5rem;
      cursor: pointer;
      transition: .3s ease;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        background: rgba(241, 92, 72, .2);
        transition: .3s ease;
      }

      i {
        font-size: 2.5rem;
        font-weight: bold;
        color: #FE634E;
      }
    }

    .thumbnail {
      width: 5rem;
      height: 5rem;
    }

    .profile-wrapper {
      .profile-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 9rem;
        right: 4rem;
        z-index: 32;
        background: white;
        min-width: 20rem;
        border-radius: 0.8rem;
        font-size: 1.4rem;
        filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 12px);      
        padding: 1rem;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 1rem;
          border-radius: .8rem;
          color: rgba(113, 128, 150, 1);
          transition: .3s ease;

          &:hover {
            background-color: #f1f2f5;
            color: rgba(26, 32, 44, 1);
            transition: .3s ease;
          }

          i {
            margin-right: 1rem;
            font-size: 2rem;
            font-weight: normal;
            color: rgba(113, 128, 150, 1);
          }
        }
      }
    }
  }
}
</style>
