import state from "./state";

/*
	包含多个基于 state 的 getter 计算属性的对象
*/
export default {
	/* ab_url () {
		return state.ab_url // 绝对路径前部
	}, */

}