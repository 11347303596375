<template>
  <div class="main-wrapper">
    <div class="backdrop transparent" v-if="showBackdrop" @click="removeAllBackItem"></div>

    <div class="condition">
      <div class="search-box">
        <i class="iconfont icon-search3"></i>
        <input class="search-input" type="text" :disabled="disabled" v-model="searchText" placeholder="搜索名称..." @keydown.enter="search">
      </div>

      <div class="add" @click="addOneItem"><i class="iconfont icon-tianjia2"></i>Add</div>
    </div>

    <div class="date-picker">
      <el-date-picker
        v-model="datetime"
        type="datetimerange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
        :disabled="disabled"
        @change="getDateResult">
      </el-date-picker>
    </div>

    <div class="pay-list">
      <el-table :data="payList" ref="paylist">
        <el-table-column prop="date" label="Date" width="150"></el-table-column>
        <el-table-column prop="label" label="Label" width="250"></el-table-column>
        <el-table-column prop="amount" label="Amount" width="100"></el-table-column>
        <el-table-column prop="tool" label="Tool" width="130"></el-table-column>
        <el-table-column prop="target.label" label="Target" width="140"></el-table-column>
        <el-table-column prop="remark" label="Remark"></el-table-column>
        <el-table-column label="Action" width="180">
          <template slot-scope="scope">
            <el-button size="small" type="success" icon="el-icon-edit" @click="editItem(scope.$index, scope.row)">编辑</el-button>
            <el-button size="small" type="info" icon="el-icon-delete" plain @click="deleteItem(scope.row.id, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      
      <div class="pagination-filters">
        <div class="show-list">
          <el-dropdown trigger="click" type="primary">
            <span class="el-dropdown-link">
              Show result<i class="el-icon-arrow-down el-icon--right"></i>
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in pageDropdownList" :key="item" @click.native="handleSizeChange(item)">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
    </div>

    <!-- 添加/修改 -->
    <div class="animate__animated animate__bounceInDown faster add-list" ref="addEditBox" v-if="showAddBox">
      <div class="item">
        <span class="title">Date</span>
        
        <el-date-picker
          v-model="active.date"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>

      <div class="item">
        <span class="title">Label</span>
        <input class="input" type="text" v-model="active.label" placeholder="请输入支付项目">
      </div>

      <div class="item">
        <span class="title">Amount</span>
        <input class="no-spin input" type="number" v-model="active.amount" placeholder="请输入支付金额">
      </div>

      <div class="item">
        <span class="title">Tool</span>
        <input class="input" type="text" v-model="active.tool" placeholder="请输入支付工具">
      </div>

      <div class="item">
        <span class="title">Target</span>

        <el-select v-model="targetValue" filterable placeholder="请选择支付对象" @change="changeTarget">
          <el-option
            v-for="item in fromList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      
      <div class="item">
        <span class="title">Remark</span>
        <input class="input" type="text" v-model="active.remark" maxlength="100" clearable placeholder="请输入备注">
      </div>
      
      <div class="item">
        <span class="title"></span>
        <button class="button cancel" @click="cancelAdd">取消</button>
        <button class="button save" :class="{ abandon: isAbandon }" @click="saveItem">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../utils/request';
import { mapState } from 'vuex';

export default {
  data () {
    return {
      timer: null,
      loading: true,
      showBackdrop: false, // 显示背景
      searchText: '',
      payList: [],
      datetime: [], // 日期时间
      // 选择日期时间
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      pageDropdownList: [5, 10, 15, 20, 30, 50, 100], // 每页显示条数列表
      pageSize: 30, // 当前展示条数
      currentPage: 1, // 当前所在页码
      total: 0, // 列表项总数
      showAddBox: false, // 显示添加框
      active: {
        date: '', // 支付时间
        label: '', // 支付项目
        amount: '', // 支付金额
        tool: '', // 支付工具

        // 支付对象
        // 注意: 设置为 null 不会报错,但为空或为 0 则会报错
        target: {
          id: null,
          label: null,
        },
        remark: '', // 支付备注
      },
      targetValue: '', // 当前 target 值
      type: 0, // 0 为添加,1 为修改,默认为 0
      activeIndex: null, // 当前操作项的 id
      disabled: false,
      isAbandon: 0, // 防止双击标识
    }
  },

  /**
   * targetID
   *    1: 味予餐饮（公司）
   *    2: 食为奴
   *    3: 美菜 UU etc
   *    4: 美团饿了么
   *    5: 淘宝京东
   *    6: 微信渠道
   *    7: 其他
   */
  props: ['targetID'],

  computed: {
    ...mapState(['fromList']),
  },

  methods: {
    // 初始化
    init () {
      this.getPayList(); // 获取列表
    },
    
    // 提示封装
    messageAlert (type, message) {
      this.$message({
        type,
        message
      })
    },
    
    // 获取日期时间格式
    getDatetime (time) {
      let curdate = new Date(time);
      let y = curdate.getFullYear();
      let m = curdate.getMonth() + 1;
      let d = curdate.getDate();
      let hh = curdate.getHours();
      let mm = curdate.getMinutes();
      let ss = curdate.getSeconds();

      m = m >= 10 ? m : '0' + m;
      d = d >= 10 ? d : '0' + d;
      hh = hh >= 10 ? hh : '0' + hh;
      mm = mm >= 10 ? mm : '0' + mm;
      ss = ss >= 10 ? ss : '0' + ss;

      let res = y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss;
      return res;
    },

    // 获取当前列表
    getPayList (payParams) {
      let _params = { target: this.targetID, page: this.currentPage, countPerPage: this.pageSize };
      if (payParams) {
        Object.assign(_params, payParams);
      }

      request.get(`/bill`, { params: _params })
      .then (res => {
        if (res.data.message) {
          if (res.data?.data?.rows?.length > 0) {
            let result = res.data.data;
            let data = result.rows;
            let count = result.count;
  
            this.loading = false;
            this.paylist = []; // 清空列表原数据
            data.map(i => {
              i.amount = i.amount ? Number(i.amount).toFixed(2) : '';
            })
  
            this.payList = data;
            this.total = count;
  
            this.disabled = false;
            this.showBackdrop = false;
          }
          else {
            this.payList = [];
            this.total = 0;
          }
        }
      })
    },

    // 点击背景移除所有显示框
    removeAllBackItem () {
      clearInterval(this.timer); // 清除定时器
    },
    
    // 搜索
    search () {
      this.searchText = this.searchText.trim();

      if (!this.searchText && this.datetime.length == 0) return;

      this.currentPage = 1; // 默认为第 1 页

      let preDatetime = this.getDatetime(this.datetime[0]);
      let nextDatetime = this.getDatetime(this.datetime[1]);

      let params = {};
      if (this.searchText) {
        params = this.datetime?.length > 0 ? {
          name: this.searchText,
          preDatetime,
          nextDatetime,
        } : {
          name: this.searchText
        };
      }
      else {
        params = this.datetime?.length > 0 ? {
          preDatetime,
          nextDatetime,
        } : {};
      }

      this.getPayList(params);
    },

    // 获取日期
    getDateResult () {
      // 获取列表中大于选择的初始日期,小于选择的终止日期
      this.searchText = this.searchText ? this.searchText.trim() : '';

      let preDatetime = this.getDatetime(this.datetime[0]);
      let nextDatetime = this.getDatetime(this.datetime[1]);

      let params = {};
      if (this.searchText) {
        params = {
          name: this.searchText,
          preDatetime,
          nextDatetime,
        }
      }
      else {
        params = {
          preDatetime,
          nextDatetime,
        }
      }

      this.getPayList(params);
    },

    // 清除日期时间
    clearDateTime () {
      let name = this.searchText ? this.searchText.trim() : '';
      let params = this.searchText ? { name } : {};
      this.getPayList(params);
    },

    // 获取每页条数
    handleSizeChange(val) {
      this.currentPage = 1; // 页码恢复到第一页
      this.pageSize = val; // 更改每页条数

      let preDatetime = this.getDatetime(this.datetime[0]);
      let nextDatetime = this.getDatetime(this.datetime[1]);
      
      let params = {};
      if (this.searchText) {
        if (this.datetime.length > 0) {
          params = {
            name: this.searchText,
            preDatetime,
            nextDatetime
          }
        }
        else {
          params = {
            name: this.searchText
          }
        }
      }
      else {
        if (this.datetime.length > 0) {
          params = {
            preDatetime,
            nextDatetime
          }
        }
      }

      this.getPayList(params);
    },

    // 获取当前所在页码
    handleCurrentChange(val) {
      // this.currentPage = this.currentPage == 1 ? this.currentPage : val;
      this.currentPage = val;
      
      let preDatetime = this.getDatetime(this.datetime[0]);
      let nextDatetime = this.getDatetime(this.datetime[1]);
      
      let params = {};
      if (this.searchText) {
        if (this.datetime.length > 0) {
          params = {
            name: this.searchText,
            preDatetime,
            nextDatetime
          }
        }
        else {
          params = {
            name: this.searchText
          }
        }
      }
      else {
        if (this.datetime.length > 0) {
          params = {
            preDatetime,
            nextDatetime
          }
        }
      }

      this.getPayList(params)
    },

    // 添加一条财务信息
    addOneItem () {
      // 状态归零
      this.active = {};
      this.active.target = { id: null, label: null };
      this.targetValue = '';
      
      this.type = 0; // 状态为添加

      this.showAddBox = true;
      this.showBackdrop = true;
    },

    // 取消添加/修改一条财务记录
    cancelAdd () {
      if (this.$refs.addEditBox) {
        this.$refs.addEditBox.classList.remove('animate__bounceInDown');
        this.$refs.addEditBox.classList.add('animate__zoomOutDown');
      }

      this.timer = setTimeout(() => {
        this.showBackdrop = false;
        this.showAddBox = false;
      }, 300)
    },

    // 删除一条
    deleteItem (id, index) {
      this.$confirm('删除后，此信息在回收站仅保存 7 天', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then (() => {
        request.delete(`/bill/${ id }`)
        .then (res => {
          if (res.data.message) {
            this.messageAlert('success', '删除成功！');

            // 只存在搜索
            if (this.searchText && this.datetime?.length == 0) {
              let name = this.searchText.trim();
              this.getPayList({ name });
            }

            // 只存在时间段
            if (!this.searchText && this.datetime?.length > 0) {
              this.getPayList({ preDatetime: this.datetime[0], nextDatetime: this.datetime[1] });
            }

            // 存在搜索和时间段
            if (this.searchText && this.datetime?.length > 0) {
              let name = this.searchText.trim();
              this.getPayList({ name, preDatetime: this.datetime[0], nextDatetime: this.datetime[1] });
            }

            // 不存在搜索和时间段
            if (!this.searchText && this.datetime?.length == 0) {
              this.getPayList();
            }

            // 列表为空
            if (this.payList.length == 0) {
              this.total = 0;
              this.disabled = true;
            }
          }
          else {
            this.messageAlert('info', '删除失败！');
          }
        })
      })
      .catch (() => {
      })
    },

    /**
     * 修改一条财务信息
     * index: 当前项在列表中的位置
     * row: 当前项的值
     */
    editItem (index, row) {
      this.type = 1; // 状态为修改
      this.showBackdrop = true; // 显示背景
      this.showAddBox = true;   // 显示修改框

      // 状态为修改时,获取当前值,不会有响应性
      this.$set(this.active, 'date', row.date);
      this.$set(this.active, 'label', row.label);
      this.$set(this.active, 'amount', row.amount);
      this.$set(this.active, 'tool', row.tool);
      this.$set(this.active, 'target', { id: row.target.id, label: row.target.label });
      this.$set(this.active, 'remark', row.remark);
      this.targetValue = row.target.id; // 编辑时 target 选项重现

      this.activeIndex = row.id; // 获取当前编辑项的 id
    },
    
    // 选择支付对象
    changeTarget (val) {
      /* // 新增
      if (this.type == 0) {
        this.$set(this.active.target, 'id', val);
      }
      
      // 修改
      if (this.type == 1) {
        this.$set(this.active.target, 'id', this.active.target.id);
      } */

      this.$set(this.active.target, 'id', val);
    },

    // 保存一条财务记录
    saveItem () {
      // 防止双击
      if (this.isAbandon > 0) {
        return;
      }

      // 获取当前支付对象的 id
      // let curPayId = this.fromList.filter(i => i.id == this.active.target.label)[0] ?. id;
      let curPayId = this.active.target.id;

      // 日期时间、支付项目、支付金额、支付工具、支付对象不得为空
      if (!this.active.date) {
        this.messageAlert('info', '请填写日期时间！')
        return;
      }

      if (!this.active.label) {
        this.messageAlert('info', '请填写支付项目！')
        return;
      }

      if (!this.active.amount) {
        this.messageAlert('info', '请填写支付金额！')
        return;
      }

      if (!this.active.tool) {
        this.messageAlert('info', '请填写支付工具！')
        return;
      }

      if (!curPayId) {
        this.messageAlert('info', '请填写支付对象！')
        return;
      }

      this.isAbandon++;

      let curDate = new Date(this.active.date);
      let y = curDate.getFullYear();
      let m = curDate.getMonth() + 1;
      let d = curDate.getDate();
      let h = curDate.getHours();
      let mi = curDate.getMinutes();

      m = m >= 10 ? m : '0' + m;
      d = d >= 10 ? d : '0' + d;
      h = h >= 10 ? h : '0' + h;
      mi = mi >= 10 ? mi : '0' + mi;

      let date = `${ y }-${ m }-${ d } ${ h }:${ mi }`;

      let params = {
        date,
        label: this.active.label,
        amount: Number(this.active.amount).toFixed(2),
        tool: this.active.tool,
        target: curPayId,
        remark: this.active.remark
      }
      
      // 如果是添加新的账务信息
      if (this.type == 0) {
        let curTarget = this.fromList.filter(i => i.id == this.active.target.label);
        let curTargetText = curTarget.length > 0 ? curTarget[0].label : '';
        let message = curTargetText && this.active.target != this.targetID ? `成功添加到 ${ curTargetText }！` : '添加成功！';
        
        // 添加一条账务账单
        request.post(`/bill`, params)
        .then (res => {
          if (res.data.message) {
            this.$message({
              type: 'success',
              message,
              duration: 1000,
              onClose: () => {
                // 动态隐藏
                this.cancelAdd();

                // 更新列表
                this.currentPage = 1;
                this.getPayList();

                // 清除痕迹
                this.activeIndex = null;
                // 放开禁用
                this.disabled = false;

                this.timer = setTimeout(() => {
                  this.isAbandon--;
                }, 1000);
              }
            })
          }
          else {
            this.messageAlert('info', '添加失败！');
          }
        })
      }

      // 如果是修改一个财务信息
      if (this.type == 1) {
        let targetText = this.fromList.filter(i => i.id == curPayId)[0].label;

        request.put(`/bill/${ this.activeIndex }`, params)
        .then (res => {
          if (res.data.message) {
            this.$message({
              type: 'success',
              message: '修改成功！',
              duration: 1000,
              onClose: () => {
                this.cancelAdd(); // 动态隐藏

                // 修改时需要考虑当前搜索内容和时间范围
                let preDatetime = this.getDatetime(this.datetime[0]);
                let nextDatetime = this.getDatetime(this.datetime[1]);

                let params = {};
                if (this.searchText) {
                  params = this.datetime?.length > 0 ? {
                    name: this.searchText,
                    preDatetime,
                    nextDatetime,
                  } : {
                    name: this.searchText
                  };
                }
                else {
                  params = this.datetime?.length > 0 ? {
                    preDatetime,
                    nextDatetime,
                  } : {};
                }

                // 更新列表
                this.getPayList(params);
                
                // 清除痕迹
                this.activeIndex = null;

                this.timer = setTimeout(() => {
                  this.isAbandon--;
                }, 1000);
              }
            })
          }
          else {
            this.$message({
              type: 'info',
              message: '修改失败！'
            })
          }
        });
      }
    },
  },

  mounted () {
    this.init();
    clearTimeout(this.timer);
  },

  beforeUpdate () {
    this.$nextTick(() => {
      // 解决 el-table 闪烁的问题
      this.$refs.paylist.doLayout();
    })
  },

  watch: {
    datetime (newval) {
      this.datetime = !newval ? [] : newval;

      if (newval.length == 0 || newval == null) {
        this.clearDateTime();
      }
    },
    searchText (newval) {
      if (!newval || newval.length == 0) {
        let preDatetime = this.getDatetime(this.datetime[0]);
        let nextDatetime = this.getDatetime(this.datetime[1]);
        
        let params = {};
        if (this.searchText) {
          if (this.datetime.length > 0) {
            params = {
              name: this.searchText,
              preDatetime,
              nextDatetime
            }
          }
          else {
            params = {
              name: this.searchText
            }
          }
        }
        else {
          if (this.datetime.length > 0) {
            params = {
              preDatetime,
              nextDatetime
            }
          }
        }

        this.getPayList(params);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.condition {
  display: flex;
  justify-content: space-between;

  .search-box {
    flex: 1;
    position: relative;
    display: flex;

    i {
      position: absolute;
      top: 13px;
      left: 15px;
      z-index: 2;
      font-size: 28px;
    }

    .search-input {
      flex: 1;
      padding: 0 20px;
      text-indent: 35px;
      border-radius: 8px;
      font-size: 14px;
      border: .1rem solid transparent;
      background-color: #f1f2f5;
      height: 54px;

      &:focus {
        border-color: #22C55E;
      }
    }
  }

  .add {
    padding: 0px 20px;
    color: #22C55E;
    background-color: #F7FAFC;
    font-size: 15px;
    margin-left: 10px;
    border-radius: 8px;
    border: .1rem solid #E2E8F0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s ease;

    &:hover {
      background-color: #eee8e8;
      border-color: #eee8e8;
      transition: .3s ease;
    }

    i {
      margin-right: .5rem;
    }
  }
}

.date-picker {
  margin: 30px 0;
}

.el-range-editor.el-input__inner {
  border-radius: 8px;
  height: 50px;
  padding: 8px 10px;
}

.add-list {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 32;
  width: fit-content;
  height: fit-content;
  margin: auto;
  padding: 50px;
  border-radius: 8px;
  background: white;
  filter: drop-shadow(rgba(0, 0, 0, 0.08) 1.2rem 1.2rem 4rem);

  .item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .title {
      font-size: 15px;
      margin-right: 10px;
      min-width: 100px;
    }
    
    .input {
      background-color: #F0FAFA;
      padding: 20px 10px;
      border-radius: 8px;
      text-indent: 10px;
      border: .1rem solid transparent;
      min-width: 300px;

      &:focus {
        border-color: #22C55E;
      }
    }

    .dropdown {
      border: .1rem solid #E2E8F0;
      border-radius: 8px;
      background: #F0FAFA;
      cursor: pointer;

      .el-dropdown-link {
        padding: 18.5px 25px;
        display: block;
      }
    }

    .button {
      width: auto;
      height: auto;
      padding: 15px 30px;
      border-radius: 8px;
      border: .1rem solid transparent;
      color: white;
      font-size: 14px;
      margin-right: 20px;
      background-color: #22C55E;
      cursor: pointer;
      transition: .3s ease;

      &.save:hover {
        background-color: #16A34A;
        transition: .3s ease;
      }

      &.cancel {
        background-color: #D9FBE6;
        color: rgba(34, 197, 94, 1);

        &:hover {
          background-color: #d2ebdc;
        }
      }
    }
  }
}
</style>
