/* 
  包装 axios 成为一个新的带 token 验证的请求方法 request
  这样就不需要在每次请求时都手动带上 token
*/

import axios from 'axios';

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/api',
  timeout: 10000,
})

request.interceptors.request.use(req => {
  let token = localStorage.getItem('token');
  if (token) {
    req.headers.token = token;
  }

  return req
})

export default request;