<template>
  <div class="home">
    <Aside/>

    <div class="home-right">
      <Header/>

      <div class="main-box">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Aside from '@/components/Aside'
import Header from '@/components/Header'

export default {
  components: {
    Aside,
    Header,
  }
}
</script>

<style scoped>
.home {
  display: flex;
  width: 100%;

  .home-right {
    flex: 1;
    margin-left: 30.8rem;
    overflow-x: hidden;

    .main-box {
      margin: 4rem;
      padding-top: 10.8rem;
    }
  }
}
</style>
