<template>
  <aside>
      <div class="logo">
        <a href="javascript:;" @click="toPage('/')"><img src="../assets/img/logo-long.jpg" alt=""></a>
      </div>
      <div class="nav">
        <div class="title">Menu</div>

        <ul class="nav-list">
          <li v-for="(menu, index) in menuList" :key="index" @mouseenter="menu.onHover = !menu.onHover" @mouseleave="menu.onHover = !menu.onHover" @click="toPage(menu.toUrl)">
            <img class="animated__animated animated_fadeIn" :src="menu.icon" alt="" v-show="!menu.onHover">
            <img class="animated__animated animated_fadeIn" :src="menu.hoverIcon" alt="" v-show=menu.onHover>
            <span class="text">{{ menu.name }}</span>
            <span class="right-arrow" :class="{ hidden: menu.showRightArrow }"><svg width="6" height="12" viewBox="0 0 6 12" fill="none" class="fill-current" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" fill="currentColor" d="M0.531506 0.414376C0.20806 0.673133 0.155619 1.1451 0.414376 1.46855L4.03956 6.00003L0.414376 10.5315C0.155618 10.855 0.208059 11.3269 0.531506 11.5857C0.854952 11.8444 1.32692 11.792 1.58568 11.4685L5.58568 6.46855C5.80481 6.19464 5.80481 5.80542 5.58568 5.53151L1.58568 0.531506C1.32692 0.20806 0.854953 0.155619 0.531506 0.414376Z"></path></svg></span>
          </li>
        </ul>

        <div class="others">
          <div class="item" @click="toPage('recycle')">
            <div class="recycle">
              <img src="../assets/img/recycle_01.svg" alt="">
              回收站
            </div>
          </div>
        </div>

        <div class="copyright">© {{ curTime }} All Rights Reserved</div>
      </div>
    </aside>
</template>

<script>
export default {
  data () {
    return {
      curTime: new Date().getFullYear(),
      menuList: [
        {
          fiexd: true,
          icon: require('../assets/img/dashboard.png'),
          hoverIcon: require('../assets/img/dashboard_hover.png'),
          name: 'Dashboards',
          showRightArrow: false,
          onHover: false,
          toUrl: '/',
        },
        {
          fiexd: true,
          icon: require('../assets/img/goodslist.png'),
          hoverIcon: require('../assets/img/goodslist_hover.png'),
          name: '商品列表',
          showRightArrow: true,
          onHover: false,
          toUrl: '/goodslist',
        },
        {
          icon: require('../assets/img/tkx.png'),
          hoverIcon: require('../assets/img/tkx_hover.png'),
          name: '味予餐饮',
          showRightArrow: true,
          onHover: false,
          toUrl: '/weiyucanying',
        },
        {
          icon: require('../assets/img/shiweinu.png'),
          hoverIcon: require('../assets/img/shiweinu_hover.png'),
          name: '食为奴',
          showRightArrow: true,
          onHover: false,
          toUrl: '/shiweinu',
        },
        {
          icon: require('../assets/img/meicai.png'),
          hoverIcon: require('../assets/img/meicai_hover.png'),
          name: '美菜UU etc',
          showRightArrow: true,
          onHover: false,
          toUrl: '/meicai',
        },
        {
          icon: require('../assets/img/meituan.png'),
          hoverIcon: require('../assets/img/meituan_hover.png'),
          name: '美团饿了么',
          showRightArrow: true,
          onHover: false,
          toUrl: '/meituan',
        },
        {
          icon: require('../assets/img/taobao.png'),
          hoverIcon: require('../assets/img/taobao_hover.png'),
          name: '淘宝京东',
          showRightArrow: true,
          onHover: false,
          toUrl: '/taobao',
        },
        {
          icon: require('../assets/img/weixin.png'),
          hoverIcon: require('../assets/img/weixin_hover.png'),
          name: '微信',
          showRightArrow: true,
          onHover: false,
          toUrl: '/weixin',
        },
        {
          icon: require('../assets/img/qita.png'),
          hoverIcon: require('../assets/img/qita_hover.png'),
          name: '其他',
          showRightArrow: true,
          onHover: false,
          toUrl: '/qita',
        },
      ]
    }
  },
  methods: {
    toPage (url) {
      this.$router.push(url);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/css/common.scss';

aside {
  width: 30.8rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 30;

  .logo {
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10.8rem;

    img {
      width: 21rem;
    }

    .nav {
      height: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 1.4rem;
        padding-bottom: 1rem;
      }
    }
  }

  .nav {
    width: 21rem;
    padding: 2rem 0 0;

    & > .title {
      font-size: 1.4rem;
      color: rgba(74, 85, 104, 1);
      border-bottom: 1px solid $borderColor;
      padding-bottom: 0.5rem;
    }

    .nav-list {
      margin: 1.5rem 0 0;
      min-height: 60vh;
      padding: 0;

      li {
        color: rgba(26, 32, 44, 1);
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        padding: 11px 0;
        cursor: pointer;
        transition: .3s ease;

        &:hover {
          // color: #989FB3;
          color: #22c55e;
          transition: .3s ease;
        }

        .icon-base {
          font-size: 2.2rem;
          margin-right: 0.625rem;
        }

        img {
          width: 2rem;
          margin-right: 1rem;
          border-radius: 4px;
        }

        .right-arrow {
          margin-left: auto;
        }
      }
    }

    .others {
      margin: 1rem 0 2rem;
      border: 1px solid #EDF2F7;
      border-left: none;
      border-right: none;

      .item .recycle {
        cursor: pointer;
        padding: 1rem 0;
        transition: .3s ease;

        &:hover {
          color: #22c55e;
          transition: .3s ease;
        }

        img {
          width: 2.2rem;
          height: 2.2rem;
          vertical-align: top;
          margin-right: .5rem;
        }
      }
    }

    .copyright {
      font-size: 1.4rem;
      color: rgba(150, 155, 160, 1);
    }
  }
}
</style>
